import moment from 'moment'

export default class DateTimeUtils {

    static validateDates(startTime: Date, endTime : Date){
        var normalizedStartTime = DateTimeUtils.normalize(moment(startTime));
        var normalizedEndTime = DateTimeUtils.normalize(moment(endTime));

        if(normalizedEndTime.getHours() == 0 && normalizedEndTime.getMinutes() == 0)
        {
            return true;
        }

        return moment(normalizedEndTime).isAfter(moment(normalizedStartTime))
    }

    static normalize(date : moment.Moment) {
        return new Date(2021, 4, 6, date.hours(), date.minutes())
    }
}