import * as React from 'react';
import {connect} from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import Layout from './Layout'

import routes from './RoutesDefinitions'

import CallbackPage from '../Services/Authentication/Components/CallbackPage';
import {ApplicationState} from '../Services/Store/storeData';

import userManager from '../Services/Authentication/Logic/userManager';
import {User} from 'oidc-client';
import axios from 'axios';
import {Dispatch} from 'redux';

//TODO: managing user permissions with https://medium.com/dailyjs/managing-user-permissions-in-your-react-app-a93a94ff9b40

interface RoutesModuleProps {
  user: User;
  isLoadingUser: boolean;
  dispatch: Dispatch;
  location: any;
}

const Routes = (props: RoutesModuleProps) => {
  console.log(props);

  // wait for user to be loaded, and location is known
  if (props.isLoadingUser || !props.location) {
    return <div>Loading...</div>;
  }

  // if location is callback page, return only CallbackPage route to allow signin process
  // IdentityServer 'bug' with hash history: if callback page contains a '#' params are appended with no delimiter
  // eg. /callbacktoken_id=...
  const url = props.location.pathname.substring(0, 9);
  if (url === '/callback') {
    const rest = props.location.pathname.substring(9);
    return <CallbackPage {...props} signInParams={`${url}#${rest}`} />;
  }

  // check if user is signed in
  userManager.getUser().then(user => {
    if (user && !user.expired) {
      // Set the authorization header for axios
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access_token;
    }
  });

  const isConnected: boolean = !!props.user;

  return (
  <BrowserRouter>
    <Layout userIsConnected = {isConnected}>
        <div>
          {routes.map((prop: any, key: any) => {
            return prop.subMenus.length === 0 
              ? <Route key={key} exact path={prop.path} component={prop.component}></Route>
              : prop.subMenus.map((prop: any, key: any) => <Route key={key} exact path={prop.path} component={prop.component}></Route>)
          })}
        </div>
    </Layout>
  </BrowserRouter>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    user: state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser,
    location: state.router.location,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);
