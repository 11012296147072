import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import styles from './ProductSearchWithDetails.style.js';

import ProductSearchAutoSuggest from './ProductAutoSuggest'

class ProductSearchWithDetailsPage extends React.Component {

  render() {
    return (
      <div> 
         <MuiThemeProvider> 
          <ProductSearchAutoSuggest/>
        </MuiThemeProvider >
        </div>
    );
  }
}

ProductSearchWithDetailsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductSearchWithDetailsPage);
