import { User } from 'oidc-client';
import {History} from 'history';
import {RouterState, connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';
import {reducer as oidcReducer} from 'redux-oidc';


import * as Counter from '../../Components/Counter';

interface OidcState {
    isLoadingUser: boolean;
    user: User;
}

// The top-level state object
export interface ApplicationState {
    counter: Counter.CounterState;
    oidc: OidcState;
    router: RouterState;
}

export const rootReducer = (history: History) => combineReducers({
    counter: Counter.reducer,
    router: connectRouter(history),
    oidc: oidcReducer
  });

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
