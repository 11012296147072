export interface ITypeTranslation {
    translation: string;
 }

export class WorkTypeTranslator {
    static translations: { [id: string]: ITypeTranslation; } = {
        "DrinkingWaterTreatment": { translation: "Ivóvízkezelés" },
        "HeatingSystemWaterTreatment": { translation: "Fűtésrendszer vízkezelés" },
        "GasInstallation": { translation: "Gázszerelés" },
        "GasApplianceService": { translation: "Gázkészülék szervíz" },
        "CompleteBuildingEngineering": { translation: "Komplett épületgépészet" }
     };


    translate(type: string) : string {
        try {
            return WorkTypeTranslator.translations[type].translation 
        } catch (error) {
            throw new TypeError(`The worktype ${type} can not be translated because there is not corresponding translation specified`)
        }
    }
}
