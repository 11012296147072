var WorkTypes = {
    DRINKING_WATER_TREATMENT: "DrinkingWaterTreatment",
    HEATING_SYSTEM_WATERTREATMENT: "HeatingSystemWaterTreatment",
    GAS_INSTALLATION: "GasInstallation",
    GAS_APPLIANCESERVICE: "GasApplianceService",
    COMPLETE_BUILDING_ENGINEERING: "CompleteBuildingEngineering",

    getAll: () =>  {
      return [
        WorkTypes.DRINKING_WATER_TREATMENT, 
        WorkTypes.HEATING_SYSTEM_WATERTREATMENT, 
        WorkTypes.GAS_INSTALLATION, 
        WorkTypes.GAS_APPLIANCESERVICE, 
        WorkTypes.COMPLETE_BUILDING_ENGINEERING
      ]
    }
};

export default WorkTypes