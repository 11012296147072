export interface ITypeTranslation {
    translation: string;
 }

export class CarTypeTranslator {
    static translations: { [id: string]: ITypeTranslation; } = {
        "NoCar": { translation: "Nincs autója" },
        "Petrol": { translation: "Benzin" },
        "Diesel": { translation: "Dízel" }
     };


    translate(type: string) : string {
        try {
            return CarTypeTranslator.translations[type].translation 
        } catch (error) {
            throw new TypeError(`The car type ${type} can not be translated because there is not corresponding translation specified`)
        }
    }
}
