import React, {useEffect, useState} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import urlJoin from 'url-join'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TabPanel from '../TabPanel'
import {ITab, ICustomer, IWork, IWorkStatus} from '../../types'
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {get, post} from 'Core/Utils/api'
import {handleError} from 'Core/Utils/ApiErrorHandler' 
import {WorkStatusTranslator} from '../../Core/WorkStatusTranslator'
import {WorkTypeTranslator} from './WorkTypeTranslator'
import {AddWorkDialog} from './AddWorkDialog'
import {useStyles, getWorkStatusStyle} from './WorksTab.style'
import NumberFormat from 'react-number-format'
import PriceInHUFComponent from 'Core/Components/PriceInHUFComponent'
import QuoitationMaker from './Quotation/QuotationMaker'
import {EditWorkDialog} from './EditWorkDialog'

interface WorkStatusComponentProps {
  customerData : ICustomer
  workId: string
}


function roundTo2Digits(number : number) {
  return Math.round(number * 100) / 100
}


function convertHoursToHoursAndMinutes(totalNumberOfHours: number){
  let hours = Math.trunc(totalNumberOfHours);
  let minutes = (totalNumberOfHours - Math.floor(totalNumberOfHours))* 60;

  return roundTo2Digits(hours) + " óra " + roundTo2Digits(minutes) + " perc"
}


const WorkStatusComponent = (props: WorkStatusComponentProps) => {
  const classes = useStyles();
  const [workStatus, setWorkStatus] = useState<IWorkStatus>();


  var workStatusEndpoint = urlJoin(REACT_APP_WAREHOUSING_URL(), 'customers', props.customerData.id.toString(), 'works', props.workId, 'status');

  const getWorkStatus = () => {
    get(workStatusEndpoint)
      .then((response: any) => {
        setWorkStatus(response.data)
      })
      .catch(error => {
        handleError(error, "Hiba a munka statusz töltése közben");
      });
  };

  useEffect(() => {
    getWorkStatus();
  }, [props.customerData]);
  


  return (
    <div> 
       <Typography className={classes.title} color="textSecondary" gutterBottom>
              Ledolgozott órák
        </Typography>
        <Typography variant="body1" gutterBottom>
            {(!workStatus?.totalWorkedHours || 0) ? "-" : workStatus!.totalWorkedHoursFormatted + ` (${roundTo2Digits(workStatus!.totalWorkedHours)} óra)`}
        </Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
              Belekerülési költség
        </Typography>
        <Typography variant="body1" gutterBottom>
             {(!workStatus?.payedExpense || 0) ? "-" : <PriceInHUFComponent price = {workStatus?.payedExpense}/>}
        </Typography>
    </div>
  );
}

export default WorkStatusComponent