import * as React from 'react';
import TabPanel from '../TabPanel'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {IEmployee} from '../../types'
import {ITab} from '../../types'
import isEmpty from 'lodash/isEmpty';
import {EditEmployeeDialog} from './EditEmployeeDialog'
import {DeleteEmployeeDialog} from './DeleteEmployeeDialog'
import { CarTypeTranslator } from 'EmployeeManagement/CarTypeTranslator';

const useStyles = makeStyles({
  title: {
    fontSize: 14,
  }
});

interface IEmployeeInfoProps {
  employeeData : IEmployee
  setEmployeeData : Function
}

const EmployeeInfoTab = (props : IEmployeeInfoProps & ITab) => {
  const carTypeTranslator = new CarTypeTranslator(); 
  const classes = useStyles();
  console.log(props.employeeData)
  return (
    <TabPanel value={props.value} index={props.index}>
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
        Név
        </Typography>
        <Typography variant="body1" gutterBottom>
        {isEmpty(props.employeeData.name) ?  "-" : props.employeeData.name}
        </Typography>
      </div>
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Telefonszám
        </Typography>
          <Typography variant="body1" gutterBottom>
          {isEmpty(props.employeeData.phone) ? "-" : props.employeeData.phone}
        </Typography>
      </div>
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          E-mail
        </Typography>
          <Typography variant="body1" gutterBottom>
          {isEmpty(props.employeeData.email) ? "-" : props.employeeData.email}
        </Typography>
      </div>
      <div>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
      Általános órabér
      </Typography>
      <Typography variant="body1" gutterBottom>
      {(!props.employeeData.generalHourlyWage || 0) ? "-" : props.employeeData.generalHourlyWage + " Ft" }
      </Typography>
      </div>
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
        Nagymunka órabér
        </Typography>
        <Typography variant="body1" gutterBottom>
        {(!props.employeeData.bigWorkHourlyWage || 0) ? "-" : props.employeeData.bigWorkHourlyWage + " Ft"}
        </Typography>
      </div>
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
        Kocsi típusa
        </Typography>
        <Typography variant="body1" gutterBottom>
        {(!props.employeeData.carType) ? "-" : carTypeTranslator.translate(props.employeeData.carType)}
        </Typography>
      </div>
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
        Kocsi fogyasztás (liter)
        </Typography>
        <Typography variant="body1" gutterBottom>
        {(!props.employeeData.carFuelConsumptionPer100km) ? "-" : props.employeeData.carFuelConsumptionPer100km + " liter / 100km"}
        </Typography>
      </div>
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
        Megjegyzés
        </Typography>
        <Typography variant="body1" gutterBottom>
        {isEmpty(props.employeeData.notes) ?  "-" : props.employeeData.notes}
        </Typography>
      </div>
      <EditEmployeeDialog editEmployeeFormInitialValues = {props.employeeData} setEmployeeData = {props.setEmployeeData}/>
      <DeleteEmployeeDialog employeeId = {props.employeeData.id}  employeeName = {props.employeeData.name} setEmployeeData = {props.setEmployeeData}/>
    </TabPanel>
    )
};

export default EmployeeInfoTab

