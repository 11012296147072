import {put} from 'Core/Utils/api'

import urlJoin from 'url-join'
import { toast } from 'react-toastify';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {handleError} from 'Core/Utils/ApiErrorHandler';
import { ICustomer} from "../../types";

const handleEditCustomerSubmit = async (customer : ICustomer) => {
  var warehousingUrl = REACT_APP_WAREHOUSING_URL();
  var customersEndpoint = urlJoin(warehousingUrl, 'customers', customer.id.toString())

  put(customersEndpoint,customer)
    .then((success) => {
      toast.success("Az ügyfél módosítása sikeresen megtörtént")
      console.log(success);
    }).catch((error) => {
      handleError(error, "Hiba a termék módosítása közben");
    })

} 
export default handleEditCustomerSubmit
