import React from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import urlJoin from 'url-join'
import { withStyles } from '@material-ui/core/styles';

import styles from '../CustomerManagement/CustomerManager/CustomerSearchAutoSuggestStyle';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {get} from 'Core/Utils/api'
import {handleError} from 'Core/Utils/ApiErrorHandler' 
import renderInputComponent from 'Core/Components/AutoSuggest/InputComponentRenderer'
import renderSuggestion from './WorkSuggestionsRenderer'
import {IWork} from '../CustomerManagement/types'

function getSuggestionValue(suggestion : IWork) {
    return suggestion.address;
}

interface IWorkAutoSuggestProps {
  rowData: any
  onRowDataChange: any
}

interface IWorkAutoSuggestState {
    searchedArticleText: string
    suggestions: Array<IWork>
}

class WorkSearchAutoSuggest extends React.Component<IWorkAutoSuggestProps, IWorkAutoSuggestState> {
    constructor(props : IWorkAutoSuggestProps) {
      super(props);

      this.state = {
        searchedArticleText: this.props.rowData.address || '',
        suggestions: [],
      }
    };
  
    fetchSuggestions(searchText : any) {
      get(urlJoin(REACT_APP_WAREHOUSING_URL(), 'customers', 'works'))
        .then(response => {
          this.setState({
            suggestions: response.data
              .filter((customer : any) => deburr(customer.address).toLowerCase().includes(deburr(searchText)))
              .slice(0, 5),
          });
        })
        .catch(function (error) {
          handleError(error, "Nem várt hiba történt az keresési javaslatok betöltésekor")
        });
    }
  
    handleSuggestionsFetchRequested = ({ value } : any)  => {
      this.fetchSuggestions(value)
    };
  
    handleSuggestionsClearRequested = () => {
      this.setState({
        suggestions: [],
      });
    };
  
    onSuggestionSelected = (event : any, { suggestion } : any) => {
      var rowDataWithWorkInfo = this.props.rowData;
      rowDataWithWorkInfo.workId = suggestion.id
      rowDataWithWorkInfo.work = suggestion.address
      
      this.props.onRowDataChange(rowDataWithWorkInfo);
    }

    handleTypedInput = (event : any, { newValue } : any) => {
      this.setState({
        searchedArticleText: newValue
      });
    }
  
    render() {
      const { classes  } : any = this.props;
  
      const autosuggestProps = {
        renderInputComponent,
        suggestions: this.state.suggestions,
        onSuggestionSelected : this.onSuggestionSelected,
        onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
        getSuggestionValue,
        renderSuggestion,
      };
  
      return (
        <div>
            <Autosuggest
              {...autosuggestProps}
              inputProps={{
                placeholder: 'Munka helyszíne',
                value: this.state.searchedArticleText,
                onChange: this.handleTypedInput,
              }}
              theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
              }}
              renderSuggestionsContainer={options => (
                <Paper {...options.containerProps} square>
                  {options.children}
                </Paper>
              )}
            />
        </div>
      );
    }
  }
  
  export default withStyles(styles)(WorkSearchAutoSuggest);