import React from "react";
import classNames from 'classnames';
import handleNewEmployeeSubmit from './handleNewEmployeeSubmit'
import EmployeeForm from './EmployeeForm'

const AddNewEmployeePage = (props: any)  => {
  return (
    <div className={classNames("widget")}> 
      <div className="widgetTitle">Új alkalmazott hozzáadása</div> 
      <EmployeeForm handleSubmit = {handleNewEmployeeSubmit} />
    </div>
  );
};

export default AddNewEmployeePage