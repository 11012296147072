import {put} from 'Core/Utils/api'

import urlJoin from 'url-join'
import { toast } from 'react-toastify';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {handleError} from 'Core/Utils/ApiErrorHandler';
import { IEmployee} from "../../types";

const handleEditEmployeeSubmit = async (customer : IEmployee) => {
  var warehousingUrl = REACT_APP_WAREHOUSING_URL();
  var customersEndpoint = urlJoin(warehousingUrl, 'employees', customer.id.toString())

  put(customersEndpoint,customer)
    .then((success) => {
      toast.success("Az alkalmazott módosítása sikeresen megtörtént")
      console.log(success);
    }).catch((error) => {
      handleError(error, "Hiba a alkalmazott módosítása közben");
    })

} 
export default handleEditEmployeeSubmit
