import  GlobalSettingsForm from "./GlobalSettingsForm";
import  {GlobalSettingsFormProps} from "./GlobalSettingsForm";
import  {IGlobalSettings} from "./types";
import * as Yup from "yup"
import { withFormik} from 'formik';


const isRequiredText = (name: any) => name + " megadása kötelező"

const GlobalSettingsFormWithFormikWrapper = withFormik<GlobalSettingsFormProps, IGlobalSettings>({
    mapPropsToValues: props => ({
        //Default value is needed because overllapping issue with when initial value changes in parent
        id: props.id || "",
        fuelPrice: props.initialFuelPrice || 0,
        dieselPrice: props.initialDieselPrice || 0,
    }),
    enableReinitialize: true,

    validationSchema: Yup.object().shape({
        fuelPrice: Yup.string()
        .required(isRequiredText("Benzin ár")),
        dieselPrice: Yup.string()
        .required(isRequiredText("Dízel ár"))
    }),

    handleSubmit(
        { id, fuelPrice, dieselPrice }: IGlobalSettings,
        { props, setSubmitting, setErrors, resetForm }) {
          var globalSettings : IGlobalSettings = {id,  fuelPrice, dieselPrice}
          props.handleSubmit(globalSettings);
          if(props.resetForm) {
            resetForm();
          }
        }
})(GlobalSettingsForm);


export default GlobalSettingsFormWithFormikWrapper

