import * as React from 'react';
import {connect} from 'react-redux';
import {User} from 'oidc-client';
import {push} from 'connected-react-router';
import userManager from '../Logic/userManager';
import {Dispatch} from 'redux';
import {useEffect} from 'react';

interface CallbackPageProps {
  dispatch: Dispatch;
  signInParams: string;
}

const CallbackPage = (props: CallbackPageProps) => {
  const successCallback = (user: User) => {
    // get the user's previous location, passed during signinRedirect()
    console.log('Call back page is called successfully')
    console.log('User')
    console.log(user)
    console.log('Sign in params: ' + props.signInParams)
    var redirectPath = user.state.path as string;

    console.log('redirect path: ' + redirectPath)

    props.dispatch(push(redirectPath));
  };

  const errorCallback = (error: Error) => {
    console.log('Error during call back page');
    console.log(error);
    props.dispatch(push('/'));
  };

  useEffect(() => {
    userManager
      .signinRedirectCallback(props.signInParams)
      .then(user => successCallback(user))
      .catch(error => errorCallback(error));
  });

  return <div>Loading...</div>;
};

export default connect()(CallbackPage);
