import React, { useState, createRef, useEffect} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { REACT_APP_WAREHOUSING_URL } from 'Envs'
import { get } from 'Core/Utils/api'
import { post } from 'Core/Utils/api'
import { patch } from 'Core/Utils/api'
import urlJoin from 'url-join'
import { Formik, Form } from "formik";
import * as yup from "yup";
import { toast } from 'react-toastify';
import {handleError} from 'Core/Utils/ApiErrorHandler';
import {IAnnualDaysOff} from '../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formField: {
      marginLeft: 5,
    }
  }),
);

let vacationDaysValidationSchema = yup.object().shape({
  numberOfDays: yup
    .number()
    .min(0, "Az szad napok száma nem lehet negatív érték")
});

interface IVacationDayProps {
    year: number
    employeeId : string
    handleSubmit? : Function
    initialValues? : IAnnualDaysOff
}

const VacationDaysForm = (props : IVacationDayProps) => {
  const classes = useStyles();

  const [isAnnualDaysOffPresent, setIsAnnualDaysOffPresent] = useState(false);
  const [annualDaysOff, setAnnualDaysOff] = useState<IAnnualDaysOff>({id : '', employeeId : props.employeeId, year: props.year, numberOfDaysOff: 0});

  const createNewAnnualDaysOff = () => {
    const createAnnualDaysOffUrl = urlJoin(REACT_APP_WAREHOUSING_URL(), 'employees', props.employeeId ,'annualdaysoff');
    post(createAnnualDaysOffUrl, {year: props.year})
      .then((response : any) => {
        toast.success(`Szabadnapok az évre ${props.year} sikeresen hozzá lett adva`)
        console.log(response);
        setIsAnnualDaysOffPresent(true)
        refreshAnnualDaysOff()
      })
      .catch((error : any) => {
        handleError(error, 'Hiba történt a szabadnapok hozzáadása közben')
      });        
}

const updateAnnualDaysOff = (numberOfDaysOff: number) => {
  const updateAnnualDaysOff = urlJoin(REACT_APP_WAREHOUSING_URL(), 'employees', props.employeeId ,'annualdaysoff', annualDaysOff.id);
  patch(updateAnnualDaysOff, {numberOfDays: numberOfDaysOff})
    .then((response : any) => {
      toast.success(`Szabadnapok száma sikeresen frissitésre került`)
      refreshAnnualDaysOff()
    })
    .catch((error : any) => {
      handleError(error, 'Hiba történt a szabadnapok frissitése közben')
    });    
}

  const refreshAnnualDaysOff = () => {
    if(props.employeeId) {
      const getAnnualDaysOff = urlJoin(REACT_APP_WAREHOUSING_URL(), 'employees', props.employeeId ,'annualdaysoff', props.year.toString());
      get(getAnnualDaysOff)
          .then(res => {
            if(res.data === '') {
              setIsAnnualDaysOffPresent(false)
            } else
              {
                setAnnualDaysOff(res.data)
                setIsAnnualDaysOffPresent(true)
              }
          })
          .catch(error => {
              toast.error(`Hiba az adatok töltése közben: ${error}`)
          })  
      }      
}

useEffect(() => {
    refreshAnnualDaysOff();
}, [props.employeeId, props.year]) //To run one time on component mount and on passed data changes

/*const initialAnnualDaysOffFormValues : IAnnualDaysOff = {
  id : '',
  employeeId : '',
  numberOfDaysOff : annualDaysOff.numberOfDaysOff,
  year: props.year
}*/
  
  return (
    <div>
    {!isAnnualDaysOffPresent ?
            <div>
              <div style = {{paddingTop: 10, color: 'red'}}> A {props.year}-es évre még nincsenek szabadnapok beállítva </div>
              <Button
                    disabled={false}
                    onClick={() => { createNewAnnualDaysOff()}}
                    variant="contained" 
                    color="secondary" 
                    style={{ marginLeft:10, marginTop:20, textTransform: 'none' }}
                    type="submit"
                    >
                        Szabadnapok beállítása
                </Button>
              </div> 
              :
              <div style={{width: 150}}> 
                <Formik
                enableReinitialize 
                initialValues={annualDaysOff}
                validationSchema={vacationDaysValidationSchema}
                onSubmit={(values, {resetForm}) => {
                  let vacationDay = JSON.parse(JSON.stringify(values)) as IAnnualDaysOff
                  updateAnnualDaysOff(values.numberOfDaysOff)
                }}
              >
                {({ errors, handleChange, touched,values }) => (
                  <Form>
                    <TextField
                      className = {classes.formField}
                      error={Boolean(touched.numberOfDaysOff) && Boolean(errors.numberOfDaysOff)}
                      autoComplete="numberOfDaysOff"
                      name="numberOfDaysOff"
                      value={values.numberOfDaysOff}
                      fullWidth
                      onChange={handleChange}
                      id="numberOfDaysOff"
                      label="Szabadnapok"
                      type="number"
                      autoFocus
                      helperText={
                        errors.numberOfDaysOff && touched.numberOfDaysOff
                          ? errors.numberOfDaysOff
                          : null
                      }
                    />
                    <div className="emptyLine"> </div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                    >
                      Mentés
                    </Button>
                  </Form>
                )}
              </Formik>
            </div> }
      </div>
      )
  }

export default VacationDaysForm