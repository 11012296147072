import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { processSilentRenew } from 'redux-oidc';
import { ApplicationState }  from './Core/Services/Store/storeData';
import { ConnectedRouter } from 'connected-react-router';

import configureStore from './Core/Services/Store/store'
import Routes from './Core/Components/Routes';

import * as serviceWorker from './serviceWorker';

import {createHashHistory} from 'history';
import {MuiThemeProvider} from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#00BCD4',
        main: '#00BCD4',
        dark: '#00BCD4'
      },
      secondary: {
        light: '#007BFF',
        main: '#007BFF',
        dark: '#007BFF',
      }
      },
});

const history = createHashHistory();
const store = configureStore(history);

if (window.location.pathname === '/silentRenew') {
  //userManager.signinSilentCallback()
  console.log('Processing silent renew');
  processSilentRenew(); 
} else {
ReactDOM.render(
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <ConnectedRouter history={ history } >
          <Routes/>
        </ConnectedRouter>
      </MuiThemeProvider>
    </Provider>, 
    document.getElementById('root'));
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
