import React, { CSSProperties } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    normalWorkday: {
      backgroundColor: '#f7f7f7',
    },
    dayOff: {
      backgroundColor: '#ffff77',
    },
    sickPay: {
      backgroundColor: '#ff6347',
    },
    notPayedDayOff: {
      backgroundColor: '#ffa805',
    },
    travellingAsDriver: {
      backgroundColor: '#00cc00',
    },
    travellingAsPassenger: {
      backgroundColor: '#98FB98',
    },
    legendColorRectangle:  {
      border: '1px solid black',
      height: 20,
      width: 50,
      marginRight: 5
    },
    legendsWrapper:  {
      display: 'flex'
    },
    legend: {
      margin: 10,
      fontSize: 14,
      display: 'flex',
      flexDirection: 'row'
    }
  }),
);


const DayStatusColorLegends = () => {
  const classes = useStyles();

  return (
      <div className = {classes.legendsWrapper}>
        <div className = {classes.legend}>
          <div className = {classNames(classes.legendColorRectangle, classes.normalWorkday)}/>
          Munkanap
        </div>
        <div className = {classes.legend}>
          <div className = {classNames(classes.legendColorRectangle)}/>
          Duplikált nap
        </div>
        <div className = {classes.legend}>
          <div className = {classNames(classes.legendColorRectangle, classes.dayOff)}/>
          Szabadság
        </div>
        <div className = {classes.legend}>
          <div className = {classNames(classes.legendColorRectangle, classes.sickPay)}/>
            Táppénz
        </div>
        <div className = {classes.legend}>
          <div className = {classNames(classes.legendColorRectangle, classes.notPayedDayOff)}/>
            Nem fizetett szabadság
        </div>
        <div className = {classes.legend}>
          <div className = {classNames(classes.legendColorRectangle, classes.travellingAsDriver)}/>
            Utazás sofőrként
        </div>
        <div className = {classes.legend}>
          <div className = {classNames(classes.legendColorRectangle, classes.travellingAsPassenger)}/>
            Utazás utasként
        </div>
      </div>
  );
}

export default DayStatusColorLegends