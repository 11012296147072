import React from "react";
import NumberFormat from 'react-number-format'

export const CurrencyNumberFormat = (props : any) => {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.floatValue,
            },
          });
        }}
        thousandSeparator
        suffix = " Ft"
        decimalScale = {2}
        allowNegative = {false}
      />
    );
}
