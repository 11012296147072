import React, { CSSProperties } from 'react';
import { useState, useEffect} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PaymentSummary from './PaymentSummary';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import urlJoin from 'url-join'
import {handleError} from 'Core/Utils/ApiErrorHandler'
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {get} from 'Core/Utils/api'
import {IWork} from '../CustomerManagement/types'
import {IEmployee} from '../EmployeeManagement/types'
import {Button} from '@material-ui/core';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';

import handleCreateWorkTable from './handleCreateWorkTable'
import handleDownloadWorksheet from './handleDownloadWorksheet'
import {ILeftDaysOffViewModel, IWorkSheetDetails} from './types'
import { CarTypeTranslator } from 'EmployeeManagement/CarTypeTranslator';
import {EditWorksheetDetailsDialog} from './EditWorksheetDetailsDialog'
import { AxiosError } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    employeeDetails:  {
      marginLeft: 10,
      width : 800
    },
    actionButtonsFrame:  {
      display: 'flex',
      flexDirection: 'row'
    },
    employeeDetailsInfoField: {
      margin: 10,
      fontSize: 14,
      display: 'flex',
      flexDirection: 'row'
    }
  }),
);

function IsArrayContainsData(array: any) {
  return Boolean(array.length)
}

interface IEmployeeSearchDetailsProps {
    employee : IEmployee
    workDays: Array<IWork>
    year : number
    month : number
    setYear : any
    setMonth : any
    refreshWorkerShifts: any
}

const EmployeeSearchDetails = (props: IEmployeeSearchDetailsProps) => {
  const classes = useStyles();
  const carTypeTranslator = new CarTypeTranslator(); 

  const [isCreateWorkdaysButtonDisabledAsJustClicked, setIsCreateWorkdaysButtonDisabledAsJustClicked] = React.useState(false)
  const [workSheetDetails, setWorkSheetDetails] = useState<IWorkSheetDetails>();

  const handleMonthChange = (event : any) => {
    setIsCreateWorkdaysButtonDisabledAsJustClicked(false);
    props.setMonth(event.target.value);
  };

  const handleYearChange = (event : any) => {
    setIsCreateWorkdaysButtonDisabledAsJustClicked(false);
    props.setYear(event.target.value);
  };

  //TODO: REFACTOR THE MESS WE HAVE TOO MUCH IF ELSE?!
const isAnnualDaysOffPresentForUser = async () => {
  if(props.employee.id && props.year) {
    const getAnnualDaysOff = urlJoin(REACT_APP_WAREHOUSING_URL(), 'employees', props.employee.id ,'annualdaysoff', props.year.toString());
    try {
      const res = await get(getAnnualDaysOff)
      if(res.data === '') {
        return false
      }
      return true;

      } catch (e) {
        toast.error(`Hiba az adatok töltése közben: ${e}`)       
        return false;
    }
  }
}

  const getWorkSheetDetails = async () => {

    if(props.employee.id && props.year && props.month) {
      const getWorkSheetDetailsRequest = urlJoin(REACT_APP_WAREHOUSING_URL(), 'employees', props.employee.id ,'workdays', props.year.toString(), props.month.toString(), 'worksheetdetails');
      get(getWorkSheetDetailsRequest)
          .then(res => {
              setWorkSheetDetails(res.data)
          })
          .catch(error => {
              toast.error(`Hiba az adatok töltése közben: ${error}`)
          }) 
    }
  }

  useEffect(() => {
    setIsCreateWorkdaysButtonDisabledAsJustClicked(false);
    getWorkSheetDetails();
  }, [props.workDays, props.employee, props.month, props.year]) 

  return (
      <div className = {classes.employeeDetails}>
          <div>
              <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Év</InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={props.year}
                      onChange={handleYearChange}
                      >
                      <MenuItem value={2019}>2019</MenuItem>
                      <MenuItem value={2020}>2020</MenuItem>
                      <MenuItem value={2021}>2021</MenuItem>
                      <MenuItem value={2022}>2022</MenuItem>
                      <MenuItem value={2023}>2023</MenuItem>
                      <MenuItem value={2024}>2024</MenuItem>
                  </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Hónap</InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={props.month}
                      onChange={handleMonthChange}
                      >
                      <MenuItem value={1}>Január</MenuItem>
                      <MenuItem value={2}>Február</MenuItem>
                      <MenuItem value={3}>Március</MenuItem>
                      <MenuItem value={4}>Április</MenuItem>
                      <MenuItem value={5}>Május</MenuItem>
                      <MenuItem value={6}>Június</MenuItem>
                      <MenuItem value={7}>Július</MenuItem>
                      <MenuItem value={8}>Augusztus</MenuItem>
                      <MenuItem value={9}>Szeptember</MenuItem>
                      <MenuItem value={10}>Október</MenuItem>
                      <MenuItem value={11}>November</MenuItem>
                      <MenuItem value={12}>December</MenuItem>
                  </Select>
              </FormControl>
          </div>
          <div className= {classes.employeeDetailsInfoField}>
              <Typography color="textSecondary" gutterBottom>
              Név:&nbsp;
              </Typography>
              <Typography variant="body1" gutterBottom>
                  {props.employee.name}
              </Typography>
          </div>
          <div className= {classes.employeeDetailsInfoField}>
              <Typography color="textSecondary" gutterBottom>
              Általános órabér:&nbsp;
              </Typography>
              <Typography variant="body1" gutterBottom>
                  {workSheetDetails == undefined || workSheetDetails.generalHourlyWage ==undefined ? "-" : workSheetDetails.generalHourlyWage + ' Ft/óra'} 
              </Typography>
          </div>
          <div className= {classes.employeeDetailsInfoField}>
              <Typography color="textSecondary" gutterBottom>
              Nagymunka órabér:&nbsp;
              </Typography>
              <Typography variant="body1" gutterBottom>
                  {workSheetDetails == undefined || workSheetDetails.bigWorkHourlyWage ==undefined ? "-" : workSheetDetails.bigWorkHourlyWage + ' Ft/óra'} 
              </Typography>
          </div>
          <div className= {classes.employeeDetailsInfoField}>
              <Typography color="textSecondary" gutterBottom>
              Benzin ár:&nbsp;
              </Typography>
              <Typography variant="body1" gutterBottom>
              {workSheetDetails == undefined || workSheetDetails.fuelPrice ==undefined ? "-" : workSheetDetails.fuelPrice + ' Ft'} 
              </Typography>
          </div>
          <div className= {classes.employeeDetailsInfoField}>
              <Typography color="textSecondary" gutterBottom>
              Dizel ár:&nbsp;
              </Typography>
              <Typography variant="body1" gutterBottom>
              {workSheetDetails == undefined || workSheetDetails.dieselPrice ==undefined ? "-" : workSheetDetails.dieselPrice + ' Ft'} 
              </Typography>
          </div>
          <div className= {classes.employeeDetailsInfoField}>
              <Typography color="textSecondary" gutterBottom>
              Kocsi fogyasztás (liter/100km):&nbsp;
              </Typography>
              <Typography variant="body1" gutterBottom>
              {workSheetDetails == undefined || workSheetDetails.carFuelConsumptionPer100km ==undefined ? "-" : workSheetDetails.carFuelConsumptionPer100km} 
              </Typography>
          </div>
          <div className= {classes.employeeDetailsInfoField}>
              <Typography color="textSecondary" gutterBottom>
              Kocsi típusa:&nbsp;
              </Typography>
              <Typography variant="body1" gutterBottom>
              {workSheetDetails == undefined || workSheetDetails.carType ==undefined ? "-" : carTypeTranslator.translate(workSheetDetails.carType)} 
              </Typography>
          </div>
          <div className= {classes.employeeDetailsInfoField}>
              <Typography color="textSecondary" gutterBottom>
              Megjegyzés:&nbsp;
              </Typography>
              <Typography variant="body1" gutterBottom>
              {isEmpty(props.employee.notes) ? "-" : props.employee.notes}
              </Typography>
          </div>
          <div className={classes.actionButtonsFrame}>
          <Button
                  disabled={isCreateWorkdaysButtonDisabledAsJustClicked || IsArrayContainsData(props.workDays) || props.employee?.id == '' || props.year == 0 || props.month == 0}
                  onClick={() => {
                     setIsCreateWorkdaysButtonDisabledAsJustClicked(true)
                     handleCreateWorkTable(props.employee?.id, props.year, props.month, props.refreshWorkerShifts); 
                    }}   
                  variant="contained" 
                  color="secondary" 
                  style={{ marginLeft:10, marginTop:20, textTransform: 'none' }}
                  type="submit"
                  >
                      Üres munkanapló készítése
              </Button>
              <Button
                  disabled={!IsArrayContainsData(props.workDays)}
                  onClick={() => { handleDownloadWorksheet(props.employee?.name, props.employee?.id, props.year, props.month) }}
                  variant="contained" 
                  color="secondary" 
                  style={{ marginLeft:10, marginTop:20, textTransform: 'none' }}
                  type="submit"
                  >
                      Munkanapló letöltése
              </Button>
              <EditWorksheetDetailsDialog
                workDays={props.workDays}
                employeeId={props.employee.id}
                year={props.year}
                month={props.month}
                editWorksheetDetailsFormInitialValues={workSheetDetails}
                refreshWorkerShifts = {props.refreshWorkerShifts}
                setWorksheetDetailsData={setWorkSheetDetails}/>
            </div>
      </div>
  );
}

export default EmployeeSearchDetails