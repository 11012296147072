import urlJoin from 'url-join'
import { toast } from 'react-toastify';
import  {IGlobalSettings} from "./types";
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {put} from 'Core/Utils/api'
import {handleError} from 'Core/Utils/ApiErrorHandler'

const handleProductDetailsChangeSubmit = (globalSettings : IGlobalSettings) => {
  console.log(globalSettings);  
  var productUrl = urlJoin(REACT_APP_WAREHOUSING_URL(),'globalsettings');
  
  put(productUrl,globalSettings)
    .then((success) => {
      toast.success("Az beállítások módosítása sikeresen megtörtént")
      console.log(success);
    }).catch((error) => {
      handleError(error, "Hiba a beállítások módosítása közben");
    })
}

export default handleProductDetailsChangeSubmit
