export interface ITypeTranslation {
    translation: string;
 }

export class WorkPaymentCategoryTranslator {
    static translations: { [id: string]: ITypeTranslation; } = {
        "General": { translation: "Általános" },
        "BigWork": { translation: "Nagymunka" }
     };


    translate(type: string) : string {
        try {
            return WorkPaymentCategoryTranslator.translations[type].translation 
        } catch (error) {
            throw new TypeError(`The work payment category ${type} can not be translated because there is not corresponding translation specified`)
        }
    }
}
