import Button from "@material-ui/core/Button";
import React from "react";
import TextField from "@material-ui/core/TextField";
import {Field, FormikProps } from 'formik';
import { IGlobalSettings } from "./types";
import { makeStyles } from '@material-ui/core/styles';

import {CurrencyNumberFormat} from 'Core/Components/Form/CurrencyNumberFormat'

const useStyles = makeStyles(theme => ({
  settingField : {
    margin: '12px'
  }

}));

export interface OtherProps {
  title?: string;
}

export interface GlobalSettingsFormProps {
  resetForm: Boolean;
  id?: string;
  initialFuelPrice?: number;
  initialDieselPrice?: number;
  handleSubmit(globalSettings : object) : void
}

const GlobalSettingsForm = (props: OtherProps & FormikProps<IGlobalSettings>) => {
  const classes = useStyles();

  const {
    values,
    handleSubmit,   
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched
  } = props;
 
  const change = (name: any, e: any) => {
    handleChange(e);
    setFieldTouched(name, true, false);
  };


  return (
   <form      
      onSubmit={handleSubmit}>
        <TextField
          className = {classes.settingField}
          id="fuelPrice"
          name="fuelPrice"
          label="Benzin ára" 
          value={values.fuelPrice}
          onChange={change.bind(null, "fuelPrice")}
          InputProps={{
            inputComponent: CurrencyNumberFormat,
          }}
          fullWidth
        />
        <TextField
          className = {classes.settingField}
          id="dieselPrice"
          name="dieselPrice"
          label="Dízel ára" 
          value={values.dieselPrice}
          onChange={change.bind(null, "dieselPrice")}
          InputProps={{
            inputComponent: CurrencyNumberFormat,
          }}
          fullWidth
        />
        <div className="emptyLine"> </div>
        <Button 
            type="submit" 
            variant="contained" 
            color="secondary" 
            disabled={!isValid}>
                Mentés
        </Button>
          </form>
 );
};

export default GlobalSettingsForm