import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import handleEditEmployeeSubmit  from "./handleEditEmployee";
import EmployeeForm  from "../../NewEmployee/EmployeeForm";
import {IEmployee} from '../../types'

const useStyles = makeStyles(theme => ({
    form : {
      width: '400px'
    }
    ,
    button: {
      marginTop : theme.spacing(3)
    }
}));

interface IEditEmployeeDialogProps {
  editEmployeeFormInitialValues : IEmployee
  setEmployeeData : Function
}

export const EditEmployeeDialog = (props : IEditEmployeeDialogProps) =>  {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button 
        type="submit"
        className={classes.button} 
        variant="contained"
        color="secondary"
        disabled = {props.editEmployeeFormInitialValues.name === ""}
        onClick={handleClickOpen} >
           Szerkesztés
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alkalmazott szerkesztése"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <EmployeeForm 
              handleSubmit = {(e : IEmployee) => {
                handleEditEmployeeSubmit(e); 
                setOpen(false);
                props.setEmployeeData(e)
              }} 
              initialValues = {props.editEmployeeFormInitialValues}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}