var ProductTypes = {
    GAS_BOILER: "GasBoiler",
    FLUE_GAS_DRAINAGE: "FlueGasDrainage",
    RADIATOR: "Radiator",
    THERMOSTAT: "Thermostat",
    PUMP: "Pump",
    SLUDGE_SEPARATOR: "SludgeSeparator",
    PIPE: "Pipe",
    PIPE_SYSTEM: "PipeSystem",
    SYSTEM_MAINTENANCE: "SystemMaintenance",
    WATER_TREATMENT: "WaterTreatment",
    COPPER_PROFILE: "CopperProfile",
    SOLDER_COPPER_PROFILE: "SolderCopperProfile",
    PRESS_COPPER_PROFILE: "PressCopperProfile",
    PRESS_PROFILE: "PressProfile",
    RELEASEABLE_PROFILE: "ReleasableProfile",
    SPIGOT: "Spigot",
    OTHER: "Other",

    getAll: () =>  {
      return [
        ProductTypes.GAS_BOILER, 
        ProductTypes.FLUE_GAS_DRAINAGE, 
        ProductTypes.RADIATOR, 
        ProductTypes.THERMOSTAT, 
        ProductTypes.PUMP, 
        ProductTypes.SLUDGE_SEPARATOR, 
        ProductTypes.PIPE, 
        ProductTypes.PIPE_SYSTEM, 
        ProductTypes.SYSTEM_MAINTENANCE, 
        ProductTypes.WATER_TREATMENT,
        ProductTypes.COPPER_PROFILE,
        ProductTypes.SOLDER_COPPER_PROFILE,
        ProductTypes.PRESS_COPPER_PROFILE,
        ProductTypes.PRESS_PROFILE,
        ProductTypes.RELEASEABLE_PROFILE,
        ProductTypes.SPIGOT,
        ProductTypes.OTHER
      ]
    }
};

export default ProductTypes