import React from 'react';
import MainFrame from './MainFrame';
import ErrorMessageBox from './ErrorMessageBox';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface LayoutProps {
  userIsConnected: boolean
  children: any,
}

const LayOut = (props: LayoutProps) => {
  return (
    <div>
      <MainFrame>
        {GetMainFrameContent(props)}
      </MainFrame>
      <ToastContainer autoClose = {10000} />
    </div>
  );
}

const GetMainFrameContent = (props: any) => {
  if (process.env.NODE_ENV ==='development') {
    return props.children
  } else if (process.env.NODE_ENV ==='production') {
    return props.userIsConnected
      ? (props.children)
      : (<ErrorMessageBox />)
  } else {
    return <div>Not handled NODE_ENV environment variable:  {process.env.NODE_ENV}</div>
  }
}

export default LayOut;
