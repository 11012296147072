export interface ITypeTranslation {
    translation: string;
 }

export class ProductTypeTranslator {
    static translations: { [id: string]: ITypeTranslation; } = {
        "GasBoiler": { translation: "Gázkazán" },
        "FlueGasDrainage": { translation: "Füstgáz elvezetés" },
        "Radiator": { translation: "Radiátor" },
        "Thermostat": { translation: "Termosztát" },
        "Pump": { translation: "Szivattyú" },
        "SludgeSeparator": { translation: "Iszap választó" },
        "Pipe": { translation: "Cső" },
        "PipeSystem": { translation: "Csőrendszer" },
        "SystemMaintenance": { translation: "Rendszer karbantartás" },
        "WaterTreatment": { translation: "Vízkezelés" },

        "CopperProfile": { translation: "Idom (rezes)" },
        "SolderCopperProfile": { translation: "Idom (forrasztós rezes)" },
        "PressCopperProfile": { translation: "Idom (presszes rezes)" },
        "PressProfile": { translation: "Idom (presszes)" },
        "ReleasableProfile": { translation: "Idom (oldható)" },
        "Spigot": { translation: "Csap" },

        "Other": { translation: "Egyéb" }
     };


    translate(type: string) : string {
        try {
            return ProductTypeTranslator.translations[type].translation 
        } catch (error) {
            throw new TypeError(`The type ${type} can not be translated because there is not corresponding translation specified`)
        }
    }
}