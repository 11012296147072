import * as React from 'react'
/// <reference path="index.d.ts" />
import MUIDataTable, {MUIDataTableColumnDef, MUIDataTableOptions} from 'mui-datatables';
import {get} from 'Core/Utils/api'
import urlJoin from 'url-join'
import { ProductTypeTranslator} from './Domain/Entities/ProductTypeTranslator'
import { UnitTranslator} from './Domain/Entities/UnitTranslator'
import {handleError} from 'Core/Utils/ApiErrorHandler' 

import {REACT_APP_WAREHOUSING_URL} from 'Envs'


interface IProps {
}


interface IState {
  products: any
  errorStatus: string
}

const columns : MUIDataTableColumnDef[] = [
  {
    name: "Id",
    label: "id",
    options: {
     display: 'false',
     filter: false,
     sort: true
    }
   },
  {
   name: "name",
   label: "Név",
   options: {
    filter: false,
    sort: true
   }
  },
  {
    name: "type",
    label: "Tipus",
    options: {
     filter: true,
     sort: true
    }
   },
  {
    name: "articleNumber",
    label: "Cikkszám",
    options: {
     filter: false,
     sort: true
    }
   },
   {
    name: "customTariffNumber",
    label: "VTSZ szám",
    options: {
     filter: false,
     sort: true
    }
   },
   {
    name: "netUnitPrice",
    label: "Egységár",
    options: {
     filter: false,
     sort: true
    }
   },
   {
    name: "quantity",
    label: "Mennyiség",
    options: {
     filter: false,
     sort: true
    }
   },
   {
    name: "unit",
    label: "Mértékegység",
    options: {
     filter: false,
     sort: true
    }
   }
 ];


 const options: MUIDataTableOptions = {
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  print: false,
  download: false,
  textLabels: { 
    body: {
      noMatch: "Nincs találat",
      toolTip: 'Segitség'
    },
    pagination: {
      next: "Következő",
      previous: "Előző",
      rowsPerPage: "Sorok száma egy oldalon",
      displayRows: "Sorok mutatása",
    },
    viewColumns: {
      title: '',
      titleAria: '',
    },
    selectedRows: {
      text: '',
      delete: '',
      deleteAria: '',
    },
    toolbar: {
      search: "Kereső",
      downloadCsv: "CSV letöltés",
      print: "Nyomtatás",
      viewColumns: "Oszlopok",
      filterTable: "Szűrés",
    },
    filter: {
      all: "Összes",
      title: "Szűrők",
      reset: "Szűrők törlése",
    }
  },
  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
    <div/> //Example about custom toolbar https://github.com/gregnb/mui-datatables/blob/master/examples/customize-toolbarselect/index.js
  ),
};

export default class ProductsSearchGrid extends React.Component<IProps, IState> {
  displayName = ProductsSearchGrid.name;
  public state: IState = {
    products: [],
    errorStatus: ''
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    let typeTranslator = new ProductTypeTranslator();
    let unitTranslator = new UnitTranslator();

    get(urlJoin(REACT_APP_WAREHOUSING_URL(), 'products'))
    .then(results => {
        var products: string[] = results.data.map((jsonObject : any)  => {
            return columns.map((column: any) => {
                  if(column.name == 'type') { 
                    return typeTranslator.translate(jsonObject[column.name]);
                  }
                  if(column.name == 'netUnitPrice') { 
                    return (jsonObject[column.name]).toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })
                  }
                  if(column.name == 'unit') { 
                    return unitTranslator.translate(jsonObject[column.name]);
                  }
                  return jsonObject[column.name!] || '';

            });

        });
        console.log({products})
        this.setState({products})
     })
    .catch(error => handleError(error, 'Hiba a keresési javaslatok töltése közben')
)
  }
  
  render() {
    return (
      <MUIDataTable
        title={"Termék kereső"}
        data ={this.state.products}
        columns={columns}
        options={options}
      />
    );
  }
}
