import * as React from 'react';

const Dashboard = () => {
  return (
    <div className="p-centered">
      <h1>Kedves felhasználó!</h1>
      <h3> A kövektező funkciók érhetől el: </h3>
      <div className="empty">
        <p className="empty-title h5">• Termék böngésző</p>
        <p className="empty-subtitle">• Új termék hozzáadása</p>
        <p className="empty-subtitle">• Termék részletek</p>
      </div>
      <h3> A kövektező funkciók hamarosan elérhetők: </h3>
      <div className="empty">
        <p className="empty-title h5">• Ügyfél kezelő</p>
        <p className="empty-subtitle">• Árajánlat késztítő</p>
        <p className="empty-subtitle">• Szállító készítő</p>
      </div>
      Ha bármi észrevétele van, kérem írjon a <a href="mailto:mokadaniel89@gmail.com">mokadaniel89@gmail.com</a> címre.
    </div>
  );
};

export default Dashboard;
