import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {deleteRequest} from 'Core/Utils/api'
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import urlJoin from 'url-join'
import {handleError} from 'Core/Utils/ApiErrorHandler';
import {sleep} from 'Core/Utils/utils';
import { toast } from 'react-toastify';
import {IEmployee} from '../../types'

const redButtonStyle = {
    color: "white",
    backgroundColor: "red",
}; 

const errorButtonTheme = createMuiTheme({
    overrides: {
        MuiButton: {
          root: {
            margin: "10px 0 0 0",
          }
        }
      },
    palette: {
      primary: { main: '#FF0000' }
    }
  }
)


interface DeleteEmployeeDialog {
     employeeId: string;
     employeeName: string;
     setEmployeeData: any;
  }

export const DeleteEmployeeDialog = (props : DeleteEmployeeDialog) =>  {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    console.log(props.employeeName)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteConfirmation = () => {
    var employeeUrl = urlJoin(REACT_APP_WAREHOUSING_URL(), 'employees', props.employeeId.toString());
    deleteRequest(employeeUrl).
      then((response : any) => {
        toast.success(`Az alkalmazott ${props.employeeName} sikeresen törlésre került`);
        const emptyEmployee : IEmployee= {
          id: '',
          name: '',
          phone: '',
          email: '',
          generalHourlyWage: 0,
          bigWorkHourlyWage : 0,
          carType: '',
          carFuelConsumptionPer100km : null,
          notes : ''
        };
        props.setEmployeeData(emptyEmployee);
      })
      .catch(error => {
        handleError(error, 'Hiba történt az alkalmazott törlésre közben')
      });
    setOpen(false);
  };
  return (
    <div>
      <MuiThemeProvider theme={errorButtonTheme}>
        <Button 
            type="submit" 
            variant="contained"
            color="primary" 
            disabled={props.employeeName === ""}
            onClick={handleClickOpen} >
                Törlés
        </Button>
      </MuiThemeProvider>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Termék törlése"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         Ön véglegesen törölni akarja az <b>'{props.employeeName}'</b> nevű alkalmazottat?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Mégse
          </Button>
          <Button 
            onClick={handleDeleteConfirmation} 
            style = {redButtonStyle} 
            autoFocus>
            Törlés 
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}