import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import urlJoin from 'url-join'
import { withStyles } from '@material-ui/core/styles';

import renderInputComponent from 'Core/Components/AutoSuggest/InputComponentRenderer'
import renderSuggestion from 'Core/Components/AutoSuggest/SuggestionsRenderer'
import ProductDetailsForm from './ProductDetails/ProductDetailsForm'
import ProductHistoryTable from './ProductHistory/ProductHistoryTable'
import ProductQuantityIncrementerFormContainer from './ProductQuantityIncrementer/ProductQuantityIncrementerFormContainer'

import styles from './ProductSearchWithDetails.style.js';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {get} from 'Core/Utils/api'
import {handleError} from 'Core/Utils/ApiErrorHandler' 



function getSuggestionValue(suggestion) {
    return suggestion.name;
  }

class ProductSearchAutoSuggest extends React.Component {
  constructor(props) {
    super(props);

    // Set the state directly. Use props if necessary.
    this.state = {
      searchedArticleText: '',
      suggestions: [],
      productName: '',
      data: [],
      productData: {
        id: null,
        name: '',
        type: null,
        articleNumber : null,
        customTariffNumber : null,
        netUnitPrice : 0,
        quantity : 0,
        unit : null,
        notes : ''
      }
    }
  };
  
    fetchSuggestions(value) {
      const inputValue = deburr(value.trim()).toLowerCase();
  
      get(urlJoin(REACT_APP_WAREHOUSING_URL(), 'products'))
        .then(response => {
          this.setState({
            suggestions: response.data
              .filter(product => deburr(product.name).toLowerCase().includes(inputValue))
              .slice(0, 10),
          });
        })
        .catch(function (error) {
          handleError(error, "Nem várt hiba történt az keresési javaslatok betöltésekor")
        });
  
    }
  
    handleSuggestionsFetchRequested = ({ value }) => {
      this.fetchSuggestions(value)
    };
  
    handleSuggestionsClearRequested = () => {
      this.setState({
        suggestions: [],
      });
    };
  
    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {      
      this.setState({ 
        productData: { 
          id : suggestion.id,
          name: suggestion.name,
          type: suggestion.type,
          articleNumber: suggestion.articleNumber,
          customTariffNumber: suggestion.customTariffNumber,
          netUnitPrice : suggestion.netUnitPrice,
          quantity: suggestion.quantity,
          unit: suggestion.unit,
          notes: suggestion.notes},
       searchedArticleText:''});
    }

    handleTypedInput = (event, { newValue }) => {
      this.setState({
        searchedArticleText: newValue
      });
    }
  
    render() {
      const { classes } = this.props;
  
      const autosuggestProps = {
        renderInputComponent,
        suggestions: this.state.suggestions,
        onSuggestionSelected : this.onSuggestionSelected,
        onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
        getSuggestionValue,
        renderSuggestion,
      };
  
      return (
        <div className="wrapper">
          <div className="widget" style={{width: 340}}>
            <div className="widgetTitle">Termék kereső</div> 
            <Autosuggest
              {...autosuggestProps}
              inputProps={{
                classes,
                placeholder: 'Írja be a termék névét vagy cikkszámát',
                value: this.state.searchedArticleText,
                onChange: this.handleTypedInput,
              }}
              theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
              }}
              renderSuggestionsContainer={options => (
                <Paper {...options.containerProps} square>
                  {options.children}
                </Paper>
              )}
            />
            <div className={classes.divider} />
            <div className={classes.divider} />
            <ProductDetailsForm
                productId={this.state.productData.id}  
                initialName={this.state.productData.name}  
                initialType={this.state.productData.type}  
                initialArticleNumber={this.state.productData.articleNumber}
                initialCustomTariffNumber={this.state.productData.customTariffNumber}
                initialNetUnitPrice={this.state.productData.netUnitPrice}
                initialQuantity={this.state.productData.quantity} 
                initialUnit={this.state.productData.unit} 
                initialNotes={this.state.productData.notes} 
                >
            </ProductDetailsForm>
          </div>
          <div className="widget">
            <div className="widgetTitle">Készlet változtató</div> 
            <ProductQuantityIncrementerFormContainer 
              productId = {this.state.productData.id} 
              initialQuantity = {this.state.productData.quantity} 
              >
            </ProductQuantityIncrementerFormContainer>
          </div>
          <div className="widget">
            <ProductHistoryTable
            productId = {this.state.productData.id}/>
          </div>
        </div>
      );
    }
  }

  ProductSearchAutoSuggest.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(ProductSearchAutoSuggest);