import {getBlob} from 'Core/Utils/api'

import urlJoin from 'url-join'
import { toast } from 'react-toastify';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {handleError} from 'Core/Utils/ApiErrorHandler';
import FileDownload from  'js-file-download';


const handleDownloadWorksheet = async (employeeName: string, employeeId : string, year : number, month : number) => {
  var warehousingUrl = REACT_APP_WAREHOUSING_URL();
  var worksheetDownloadEndpoint = urlJoin(warehousingUrl, 'employees', employeeId.toString(), "workdays", year.toString(), month.toString(), "pdf")

  getBlob(worksheetDownloadEndpoint)
    .then((response) => {
      console.log('The worksheet pdf has been successfully returned from the backend');
      console.log(response.data);
      FileDownload(new Blob([response.data]), `${employeeName} ${month}-${year} - Munkanapló.pdf`);
    }).catch((error) => {
      handleError(error, "Hiba a munkanapló letöltése közben");
    })

} 
export default handleDownloadWorksheet
