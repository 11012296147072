import { createStore, applyMiddleware } from 'redux';
import createOidcMiddleware from 'redux-oidc';
import {loadUser } from "redux-oidc";
import logger from 'redux-logger';
import {routerMiddleware} from 'connected-react-router';
import userManager from '../Authentication/Logic/userManager';
import { rootReducer } from './storeData'
import { History } from 'history';
import thunkMiddleware from 'redux-thunk';

export default function configureStore(history: History) {
  userManager.events.addSilentRenewError(function (error) {
      console.error('error while renewing the access token', error);
  });

  const oidcMiddleware = createOidcMiddleware(userManager);
  const allReducers = rootReducer(history);

  const store = createStore(allReducers, applyMiddleware(
    oidcMiddleware,
    thunkMiddleware,
    routerMiddleware(history),
    logger
  ));

  loadUser(store, userManager);

  return store;
}