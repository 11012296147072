import  NewProductForm from "./NewProductForm";
import  {ProductFormValues,  ProductFormProps} from "./NewProductForm";
import * as Yup from "yup"
import { withFormik} from 'formik';


const isRequiredText = (name: any) => name + " megadása kötelező"
const minValueErrorText = () =>"A megadott érték nem lehet negatív szám"
const onlyIntegerErrorText = () =>"A megadott érték csak egész szám lehet"

const InputForm = withFormik<ProductFormProps, ProductFormValues>({
    mapPropsToValues: props => ({
        //Default value is needed because overllapping issue with when initial value changes in parent
        id: props.id || "",
        name: props.initialName || "", 
        type: props.initialType || "", 
        articleNumber: props.initialArticleNumber || "",
        customTariffNumber: props.initialCustomTariffNumber || "",
        netUnitPrice: props.initialNetUnitPrice || "",
        quantity: props.initialQuantity || "",
        unit: props.initialUnit || "",
        notes: props.initialNotes || "", 
    }),
    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required(isRequiredText("Név"))
        .max(255, "A név nem lehet nagyobb 255 karakternél"),
      type: Yup.string()
        .required(isRequiredText("Tipus")),
      articleNumber: Yup.string()
        .required(isRequiredText("Cikkszám"))
        .max(20, "A cikkszám nem lehet nagyobb 20 karakternél"),
      customTariffNumber: Yup.string()
        .max(32, "A VTSZ szám nem lehet nagyobb 32 karakternél"),
      netUnitPrice: Yup.string()
        .required(isRequiredText("Egységár (Áfa nélkül)")),
      unit: Yup.string()
        .required(isRequiredText("Mértékegység")),
      quantity: Yup.number()
        .required(isRequiredText("Mennyiség"))
        .min(0, minValueErrorText)
        .integer(onlyIntegerErrorText),
      notes: Yup.string()
        .max(1000, "A megjegyzés nem lehet nagyobb 1000 karakternél"),
    }),

    handleSubmit(
        { id, name, type, netUnitPrice, articleNumber, customTariffNumber, quantity, unit, notes }: ProductFormValues,
        { props, setSubmitting, setErrors, resetForm }) {
          props.handleSubmit({id, name, type, netUnitPrice, articleNumber, customTariffNumber, unit, quantity, notes});
          if(props.resetForm) {
            resetForm();
          }
        }
})(NewProductForm);


export default InputForm

