import * as React from 'react';
import TextField from "@material-ui/core/TextField";
import {FormikProps, Field } from 'formik';

import Button from '@material-ui/core/Button';

export type QuantityIncrementerFormValues = {
  productId: string;
  quantity: number;
  incrementQuantity?: number;
}

export interface IncrementerInitialValues {
  productId?: string;
  initialQuantity?: number;
}

const ProductQuantityIncrementerFormContainer = (props: FormikProps<QuantityIncrementerFormValues>) => {
  const {
    values,
    handleSubmit,   
    errors,
    touched,
    handleChange,
    setFieldValue,
    isValid,
    setFieldTouched
  } = props;
 
  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
   <form onSubmit={handleSubmit}>
      <TextField
        id="quantity"
        name="quantity"
        label="Készlet"
        type="number"
        value={values.quantity}
        onChange={change.bind(null, "quantity")}
        fullWidth
      />
      <div className="emptyLine"/>
      <div className="emptyLine"/>
      <div>
          <TextField
            id="incrementQuantity"
            name="incrementQuantity"
            label="Adott mennyiséggel"
            value={values.incrementQuantity}
            type="number"
            onChange={change.bind(null, "incrementQuantity")}
            margin="normal"
            variant="outlined"
          />
        </div>
        <div>
        <Button 
          color="primary" 
          variant="outlined"
          disabled = {props.values.productId === ""}
          onClick={() => setFieldValue("quantity", values.quantity + (values.incrementQuantity as number))}>
           Hozzáadás
        </Button>
        <Button 
          color="primary" 
          variant="outlined" 
          disabled = {props.values.productId === ""}
          onClick={() => setFieldValue("quantity", values.quantity - (values.incrementQuantity as number))}>
            Elvétel
        </Button>
        </div>
        <div className="emptyLine"/>
        <Button 
            type="submit" 
            variant="contained" 
            color="secondary" 
            disabled={!isValid || props.values.productId === ""}>
                Mentés
        </Button>
      </form>
 );
};

export default ProductQuantityIncrementerFormContainer
