import React from 'react'
import GlobalSettingsFormWrapper from './GlobalSettingsFormWithFormikWrapper'
import { get } from 'Core/Utils/api'
import { REACT_APP_WAREHOUSING_URL } from 'Envs'
import urlJoin from 'url-join'
import { toast } from 'react-toastify';

import handleGlobalSettingsChangeSubmit from './handleGlobalSettingsChangeSubmit'

import  {IGlobalSettings} from "./types";

let GlobalSettingsPage = (props : any) => {
    const { useState, createRef, useEffect} = React;
    const [globalSettings, setGlobalSettings] = useState<IGlobalSettings>();
    const globalSettingsUrl =  urlJoin(REACT_APP_WAREHOUSING_URL(), 'globalsettings');

    const getGlobalSettings = () => {
            get(globalSettingsUrl)
                .then(res => {
                    setGlobalSettings(res.data)
                })
                .catch(error => {
                    toast.error(`Hiba az adatok töltése közben: ${error}`)
                })        
    }

    useEffect(() => {
        getGlobalSettings()
      }, [])

  return (
    <div className="widget" style = {{width : 300}}>
      <div className="widgetTitle">Általános beállítások</div>
        
      <GlobalSettingsFormWrapper
        resetForm = {false}
        id = {globalSettings?.id} 
        initialFuelPrice = {globalSettings?.fuelPrice} 
        initialDieselPrice = {globalSettings?.dieselPrice} 
        handleSubmit = {(globalSettings: IGlobalSettings) => {handleGlobalSettingsChangeSubmit(globalSettings);
        }}
        >
    </GlobalSettingsFormWrapper>
    </div>
  )
}

export default GlobalSettingsPage