import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {deleteRequest} from 'Core/Utils/api'
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import urlJoin from 'url-join'
import {handleError} from 'Core/Utils/ApiErrorHandler';
import {sleep} from 'Core/Utils/utils';
import { toast } from 'react-toastify';

const redButtonStyle = {
    color: "white",
    backgroundColor: "red",
}; 

const errorButtonTheme = createMuiTheme({
    overrides: {
        MuiButton: {
          root: {
            margin: "10px 0 0 0",
          }
        }
      },
    palette: {
      primary: { main: '#FF0000' }
    }
  }
)


interface DeleteProductProps {
    productName: string;
    productId: string;
  }

export const DeleteProductDialog = (props : DeleteProductProps) =>  {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    console.log(props.productName)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteConfirmation = () => {
    var warehousingUrlWithProductId = urlJoin(REACT_APP_WAREHOUSING_URL(), 'products', props.productId.toString());
    deleteRequest(warehousingUrlWithProductId).
      then((response : any) => {
        toast.success(`Az új cikk ${props.productName} sikeresen törlésre került`)
        sleep(1500).then(() => window.location.reload());
      })
      .catch(error => {
        handleError(error, 'Hiba történt az új cikk hozzáadása közben')
      });
    setOpen(false);
  };
  return (
    <div>
      <MuiThemeProvider theme={errorButtonTheme}>
        <Button 
            type="submit" 
            variant="contained"
            color="primary" 
            disabled={props.productName === ""}
            onClick={handleClickOpen} >
                Törlés
        </Button>
      </MuiThemeProvider>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Termék törlése"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         Ön véglegesen törölni akarja az <b>'{props.productName}'</b> nevű terméket?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Mégse
          </Button>
          <Button 
            onClick={handleDeleteConfirmation} 
            style = {redButtonStyle} 
            autoFocus>
            Törlés 
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}