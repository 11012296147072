import React, { CSSProperties } from "react";
import {REACT_APP_WEBAPP_URL} from 'Envs'
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {REACT_APP_AUTH_URL} from 'Envs'
import {REACT_APP_EXAMPLE_API_URL} from 'Envs'


const errorMessageBoxStyle = {
  margin: '10px 0',
  padding: '10px',
  border: "thin solid #FF0000",
  borderRadius: '5px',
  color: '#D8000C',
  backgroundColor: ' #FFBABA',
  width: '600px',
  height: 'auto',
  marginLeft: 'auto', 
  marginRight: 'auto',
  textAlign: 'center',
} as CSSProperties

const ErrorMessageBox = () => {
    return(
      <div style = {errorMessageBoxStyle} >
        Az alkalmazás használatához kérem jelentkezzen be!
      </div> 
    );
}

export default ErrorMessageBox