import { createUserManager } from 'redux-oidc';
import {UserManagerSettings, UserManager } from 'oidc-client';
import {REACT_APP_WEBAPP_URL} from 'Envs';
import {REACT_APP_AUTH_URL} from 'Envs';

let redirectUri = `${REACT_APP_WEBAPP_URL()}/#/callback`
let silentRedirectUri = `${REACT_APP_WEBAPP_URL()}/silentRenew.html`


const userManagerConfig: UserManagerSettings = {
  client_id: 'spa',
  redirect_uri: redirectUri, //'http://localhost:3000/#/callback'
  response_type: 'token id_token',
  scope:"openid profile api1 warehousing",
  authority: REACT_APP_AUTH_URL(), //'http://localhost:5000',
  silent_redirect_uri: silentRedirectUri,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true
};

class AuthService {
   UserManager : UserManager;
   constructor() {
      this.UserManager = createUserManager(userManagerConfig);

      this.UserManager.events.addSilentRenewError((e) => {
         console.log("silent renew error", e.message);
      });

      this.UserManager.events.addUserLoaded((e) => {
         console.log("USER IS LOADED", e);
      });
   }
}
const userManager = new AuthService().UserManager;
export default userManager;


//1st approach
//TODO: revert back to auth code flow because that is better: change this file, Routes.tsx and change back older version for Identity
//Then try this silent sign in
//Source: https://stackoverflow.com/questions/50205407/onelogin-oidc-token-refresh-in-react
/*userManager.events.addAccessTokenExpiring(() =>
{
   userManager.signinSilent({scope: "openid profile api1", response_type: 'token id_token'})
           .then((user: Oidc.User) =>
           {
               console.log("User is logged in again" + user)
           })
           .catch((error: Error) =>
           {
              //Work around to handle to Iframe window timeout errors on browsers
              console.log('There was an error ' + error)

           });
   });
*/


//2nd approach:
//We get indeed a new access token in case of implicit flow. but it is not added to the header only if we refresh page (so regresh Routes.tsx)

//Third approach:
//Popup if expired

//4th approach:
//Nice set up https://medium.com/@franciscopa91/how-to-implement-oidc-authentication-with-react-context-api-and-react-router-205e13f2d49

