import React from "react";
import classNames from 'classnames';
import handleNewCustomerSubmit from './handleNewCustomerSubmit'
import CustomerForm from './CustomerForm'

const AddNewCustomerPage = (props: any)  => {
  return (
    <div className={classNames("widget")}> 
      <div className="widgetTitle">Új ügyfél hozzáadása</div> 
      <CustomerForm handleSubmit = {handleNewCustomerSubmit} />
    </div>
  );
};

export default AddNewCustomerPage