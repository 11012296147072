import {post} from 'Core/Utils/api'

import urlJoin from 'url-join'
import { toast } from 'react-toastify';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {handleError} from 'Core/Utils/ApiErrorHandler';

const handleCreateWorkTable = async (employeeId : string, year : number, month : number, refreshWorkTable : any) => {
  var warehousingUrl = REACT_APP_WAREHOUSING_URL();
  var generateWorkDaysEndpoint = urlJoin(warehousingUrl, 'employees', employeeId.toString(), "workdays", year.toString(), month.toString())

  post(generateWorkDaysEndpoint)
    .then((success) => {
      toast.success("Az munkanapló sikeresen elkészült")
      console.log(success);
      refreshWorkTable();
    }).catch((error) => {
      handleError(error, "Hiba a munkanapló készítése közben");
    })

} 
export default handleCreateWorkTable
