import * as React from 'react';
import TabPanel from '../TabPanel'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {ICustomer} from '../../types'
import {ITab} from '../../types'
import isEmpty from 'lodash/isEmpty';


const useStyles = makeStyles({
  title: {
    fontSize: 14,
  }
});

interface PaymentsTab {
  customerData : ICustomer
}

const CustomerInfoTab = (props : PaymentsTab & ITab) => { 
  const classes = useStyles();

  return (
    <TabPanel value={props.value} index={props.index}>
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
        Nincs rendelkezésre álló adat
        </Typography>
        <Typography variant="body1" gutterBottom>
        {}
        </Typography>
      </div>

    </TabPanel>
    )
};

export default CustomerInfoTab

