import React from "react";
import {get} from 'Core/Utils/api'
import urlJoin from 'url-join'
import MUIDataTable, {MUIDataTableColumnDef, MUIDataTableOptions} from 'mui-datatables';
import { HistoryTypeTranslator} from './HistoryTypeTranslator'

import {handleError} from 'Core/Utils/ApiErrorHandler' 

import {REACT_APP_WAREHOUSING_URL} from 'Envs'


const columns : MUIDataTableColumnDef[] = [
  {
    label: "Id",
    name: "id",
    options: {
     display: 'false',
     filter: false,
     sort: true
    }
   },
  {
   label: "Dátum",
   name: "occurredOn",
   options: {
    filter: false,
    sort: true
   }
  },
  {
    label: "Mi történt",
    name: "type",
    options: {
     filter: false,
     sort: true
    }
   },
  {
    label: "Mennyiség",
    name: "deltaQuantity",
    options: {
     filter: false,
     sort: true
    }
   }
 ];

 const options : MUIDataTableOptions = {
  responsive: "scrollFullHeight",
  filter : false,
  print: false,
  download: false,
  selectableRows: 'none',
  textLabels: { 
    body: {
      noMatch: "Nincs történet",
      toolTip: 'Segitség'
    },
    pagination: {
      next: "Következő",
      previous: "Előző",
      rowsPerPage: "Sorok száma egy oldalon",
      displayRows: "Sorok mutatása",
    },
    viewColumns: {
      title: '',
      titleAria: '',
    },
    selectedRows: {
      text: '',
      delete: '',
      deleteAria: '',
    },
    toolbar: {
      search: "Kereső",
      downloadCsv: "CSV letöltés",
      print: "Nyomtatás",
      viewColumns: "Oszlopok",
      filterTable: "Szűrés",
    },
    filter: {
      all: "Összes",
      title: "Szűrők",
      reset: "Szűrők törlése",
    }
  }
};

interface HistoryTableProps {
  productId: string;

};
interface HistoryTableState {
  productId: string;
  histories: any // like this
};

export default class ProductHistoryTable extends React.Component<HistoryTableProps, HistoryTableState> {
  displayName = ProductHistoryTable.name;
  constructor(props : any) {
      super(props);
      
      this.state = {
          productId : props.productId,
          histories: [],
      }
  }

  componentDidUpdate(prevProps: any , prevState: any) {
    if (prevProps.productId !== this.props.productId) {
      this.getData();
    } 
  }

  getData = () => {
    if (this.props.productId == null) {
      return;
    }

    let historyTypeTranslator = new HistoryTypeTranslator();
    var url = urlJoin(REACT_APP_WAREHOUSING_URL(), 'products', this.props.productId.toString(), 'history');

    get(url)
    .then(results => {
      console.log(results);
        var fetchedHistories = results.data.map((jsonObject : any) => {
            return columns.map((column : any) => {
              if(column.name === 'type') { 
                return historyTypeTranslator.translate(jsonObject[column.name]);
              }
                return jsonObject[column.name] || '';
            });
        });
        console.log({fetchedHistories})
        this.setState({
          productId : this.props.productId,
          histories: fetchedHistories,
        })
     })
    .catch(error => {
      handleError(error,'Hiba a terméktörténetek töltése közben')
      })
  }
  
  render() {
    return (
      <MUIDataTable
        title={"Termék történet"}
        data={this.state.histories}
        columns={columns}
        options={options}
      />
    );
  }
}