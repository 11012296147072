
import Background from './blue_patt.png';

const drawerWidth = 270;

const styles = theme => ({
  white: {
    color: theme.palette.common.white
  },
  whiteBg: {
    backgroundColor: theme.palette.common.white
  },
  menuItem: {
    color: theme.palette.common.white,
    fontSize: '11px',
    fontWeight: '900'
  },
  title: {
    color: '#ffffff',
    fontWeight: 'bold',
    marginRight: '20px'
  },
  menuTitle: {
    color: '#525252',
    fontSize: '24px',
    fontWeight: '600',
    width: '450px'
  },
  subMenuList: {
    padding: '0 0 0 15px'
  },
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#ffffff',
    borderColor: '#e7e7e7',
    boxShadow: '0px 0px 0px 0px',/* It is needed to remove some inherited css*/
    borderBottom: '1px solid #E5E5E5',
    width: `calc(100% - 74px)`,

    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    color:'#353535',
    marginLeft: 12,
    marginRight: 36,    
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundPosition: 'left 40px',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'repeat',
    backgroundImage: `url(${Background})`,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundPosition: 'left 40px',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'repeat',
    backgroundImage: `url(${Background})`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    backgroundColor: '#F5F5F5' },
});


export default styles;
