import {ErrorCodesTranslator} from 'Core/Utils/ErrorCodesTranslator'

type Error = {
  code : string,
  message : string
}

export const formatErrors = (errors :  Error[]): string =>  {
  if(errors == undefined) {
    return '';
  }
  
  var translator = new ErrorCodesTranslator();

  return errors.map((e : Error) => translator.translate(e.code)).join('\r\n')
}