import React, { Component } from "react";
import MaterialTable from "material-table";
import WorkSearchAutoSuggest from './WorkSearchAutoSuggest'
import { REACT_APP_WAREHOUSING_URL } from 'Envs'
import { get } from 'Core/Utils/api'
import { post } from 'Core/Utils/api'
import { put } from 'Core/Utils/api'
import { deleteRequest } from 'Core/Utils/api'
import urlJoin from 'url-join'
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';

import PaymentSummary from './PaymentSummary';
import {handleError} from 'Core/Utils/ApiErrorHandler' 

import moment from 'moment'
import WorkTimePicker from './WorkTimePicker'

import PeopleList from 'Core/Components/PeopleList'
import EmployeeSearchDetails from './EmployeeSearchDetails'
import DayStatusColorLegends from './DayStatusColorLegends'
import DateTimeUtils from './DateTimeUtils'
import VacationDaysDetails from "./VacationDaysDetails";

    //https://levelup.gitconnected.com/react-material-table-crud-operations-with-restful-api-data-ca1af738d3c5

const useStyles = makeStyles((theme) => ({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    employeeSearchAndDetails:  {
        display: 'flex',
        flexDirection: 'row'
    }
  }));

function IsArrayContainsData(array) {
    return Boolean(array.length)
}


//TODO: disable enter for submitting: https://github.com/mbrn/material-table/issues/2270


const EmployeeWorksheet = () => {
    const classes = useStyles();
    const { useState, createRef, useEffect} = React;

    const [employee, setEmployee] = useState({id : '', name : ''});
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [workDays, setWorkDays] = useState([]);

    const materialTableRef = createRef();

    const employeeWorkDaysUrl =  urlJoin(REACT_APP_WAREHOUSING_URL(), 'employees','workdays');

    const refreshWorkerShifts = () => {
        if(employee.id && year && month) {
            const employeeWorkDaysForDateUrl = urlJoin(REACT_APP_WAREHOUSING_URL(), 'employees', employee.id ,'workdays', year.toString(), month.toString());
            get(employeeWorkDaysForDateUrl)
                .then(res => {
                    setWorkDays(res.data)
                })
                .catch(error => {
                    handleError(error,`Hiba az adatok töltése közben.`)
                })        
            }
    }
    
    useEffect(() => {
        refreshWorkerShifts();
    }, [employee, month, year]) //To run one time on component mount and on passed data changes

    const handleRowUpdate = (newData, oldData, resolve) => {
        if(newData.plusDailyWage) {
            newData.plusDailyWage = parseInt(newData.plusDailyWage); //Put as integer instead of string
        }
        if(newData.notes === undefined) {
            newData.notes = '';
        }
        if(Number.isNaN(newData.doneDistanceInKm)) {
            newData.doneDistanceInKm = 0; 
        }
        newData.startTime =  moment(newData.startTime)
            .year(oldData.year).month(oldData.month).date(oldData.day)//We need the set te correct date and not the current one
            .format('YYYY-MM-DD HH:mm:ss'); //We need the set te correct date and not the current one
        newData.endTime =  moment(newData.endTime)
            .year(oldData.year).month(oldData.month).date(oldData.day)
            .format('YYYY-MM-DD HH:mm:ss')//Needed because utc date would be sent otherwise

        put(urlJoin(employeeWorkDaysUrl, newData.id.toString()), newData)
            .then(res => {
                refreshWorkerShifts();
                toast.success(`A kiválasztott sor sikeresen módosítva lett`);
                resolve();
            })
            .catch(error => {
                handleError(error,`Nem várt hiba a sor mentése közben`)

                resolve()
            })
    }

    const handleDeleteWorkDay = (workDayId) => {
        deleteRequest(urlJoin(employeeWorkDaysUrl, workDayId.toString()))
            .then(res => {
                refreshWorkerShifts();
                toast.success(`A kiválasztott sor sikeresen törölve lett`)
            })
            .catch(error => {
                handleError(error,`Nem várt hiba a sor törlése közben`)
            })
    }

    const handleDuplicateWorkDay = (workDayId) => {
        post(urlJoin(employeeWorkDaysUrl, workDayId.toString(), 'duplicate'), {})
            .then(res => {
                refreshWorkerShifts();
                toast.success(`Új sor sikeresen hozzáadva`)

            })
            .catch(error => {
                handleError(error,`Nem várt hiba sor duplikálása közben`)
            })
    }

    const handleMarkWorkdayAsDayOff = (workDayId) => {
        post(urlJoin(employeeWorkDaysUrl, workDayId.toString(), 'dayoff'), {})
            .then(res => {
                refreshWorkerShifts();
                toast.success(`A munkanap sikeresen szabadság nap lett`)

            })
            .catch(error => {
                handleError(error,`Nem várt hiba sor szabadság készítése közben`)
            })
    }

    const handleMarkWorkdayAsSickPay = (workDayId) => {
        post(urlJoin(employeeWorkDaysUrl, workDayId.toString(), 'sickpay'), {})
            .then(res => {
                refreshWorkerShifts();
                toast.success(`A munkanap sikeresen táppénzes nap lett`)

            })
            .catch(error => {
                handleError(error,`Nem várt hiba táppénzes nap készítése közben`)
            })
    }

    const handleMarkWorkdayAsNotPayedDayOff = (workDayId) => {
        post(urlJoin(employeeWorkDaysUrl, workDayId.toString(), 'notpayeddayoff'), {})
            .then(res => {
                refreshWorkerShifts();
                toast.success(`A munkanap sikeresen nem fizetett szabadság lett`)

            })
            .catch(error => {
                handleError(error,`Nem várt hiba nem fizetett szabadság készítése közben`)
            })
    }

    const handleMarkWorkdayAsTravellingAsDriver = (workDayId) => {
        post(urlJoin(employeeWorkDaysUrl, workDayId.toString(), 'travelling-as-driver'), {})
            .then(res => {
                refreshWorkerShifts();
                toast.success(`A munkanap sor sikeresen utazássá (söfőr) lett állítva.`)

            })
            .catch(error => {
                handleError(error,`Nem várt hiba.`)
            })
    }

    const handleMarkWorkdayAsTravellingAsPassenger = (workDayId) => {
        post(urlJoin(employeeWorkDaysUrl, workDayId.toString(), 'travelling-as-passenger'), {})
            .then(res => {
                refreshWorkerShifts();
                toast.success(`A munkanap sor sikeresen utazássá (utas) lett állítva.`)

            })
            .catch(error => {
                handleError(error,`Nem várt hiba.`)
            })
    }

    return (
        <div class="widget" style = {{width : 1600}}>
            <div className="widgetTitle">Munkanapló</div>
            <div className={classes.employeeSearchAndDetails}>
                <div><PeopleList height = {350} listEndpointRoute= 'employees' setSelectedPerson = {setEmployee}/> </div>
                <EmployeeSearchDetails 
                    employee = {employee}
                    workDays = {workDays}
                    year = {year}
                    month = {month}
                    setYear = {setYear}
                    setMonth = {setMonth}
                    refreshWorkerShifts = {refreshWorkerShifts}
                    />
                <VacationDaysDetails 
                    employee = {employee}
                    workDays = {workDays}
                    year = {year}
                    month = {month}
                    setYear = {setYear}
                    setMonth = {setMonth}
                    refreshWorkerShifts = {refreshWorkerShifts}
                    />
            </div>
            
            <br/>
            <br/>
            <DayStatusColorLegends/>
            <br/>
            <MaterialTable
                tableRef={materialTableRef}
                title="Bértábla"
                localization={{
                     body:{ 
                         emptyDataSourceMessage:'Nincs rendelkezésre álló adat'
                             }}}
                columns={[
                    { field: 'id', hidden : true },
                    { title: 'Nap', field: 'day', editable: 'never' },
                    { title: 'Nap', field: 'dayName', editable: 'never' },
                    {
                        title: 'Érkezés',
                        field: 'startTime',
                        type: 'datetime',
                        render: (data) => {
                            if (data.startTime != '' && data.startTime != null) {
                                return moment(data.startTime).format("HH:mm")
                            }
                            return ''
                        },
                        validate: rowData => DateTimeUtils.validateDates(rowData.startTime, rowData.endTime),              
                        editComponent: ({ rowData, value, onChange }) => 
                            <WorkTimePicker 
                                value={value} 
                                onChange={onChange} 
                                validate = {() => DateTimeUtils.validateDates(value, rowData.endTime)} />,
                    },
                    {
                        title: 'Távozás',
                        field: 'endTime',
                        type: 'datetime',
                        render: (data) => {
                            if (data.endTime != '' && data.endTime != null) {
                                return moment(data.endTime).format("HH:mm")
                            }
                            return ''
                        },
                        editComponent: ({ rowData, value, onChange }) => 
                            <WorkTimePicker 
                            value={value} 
                            onChange={onChange}
                            validate = {() => DateTimeUtils.validateDates(rowData.startTime, value)}/>,
                    },
                    {
                        title: 'Ledolgozott óra',
                        field: 'workedHours',
                        editable: 'never',
                        render: (rowData) => {
                            if (!rowData.startTime || !rowData.endTime) {
                                return ''
                            }
                            return moment(rowData.endTime)
                                    .subtract(
                                        { 
                                            hours: 
                                                moment(rowData.startTime).hour(), 
                                            minutes:  
                                                moment(rowData.startTime).minutes() })
                                    .format("HH:mm");
                        }
                    },
                    {
                        title: 'MunkaId',
                        field: 'workId',
                        hidden: true
                    },
                    {
                        title: 'Munka helye',
                        field: 'address',
                        editComponent: ({ rowData, onRowDataChange }) => <WorkSearchAutoSuggest rowData={rowData} onRowDataChange={onRowDataChange} />
                    },
                    { 
                        title: 'Megtett út (km)', 
                        field: 'doneDistanceInKm',
                        type: 'numeric',
                        validate: rowData => 
                            parseInt(rowData.doneDistanceInKm) < 
                            0 
                            ? { isValid: false, helperText: 'A megtett út nem lehet negatív érték'} :{isValid: true }     },
                    { 
                        title: 'Plus bér', 
                        field: 'plusDailyWage', 
                        type: 'currency',
                        currencySetting:{ locale: 'hu',currencyCode:'huf', minimumFractionDigits:0, maximumFractionDigits:2},
                        validate: rowData => 
                            parseInt(rowData.plusDailyWage) < 
                            0 
                            ? { isValid: false, helperText: 'A plus bér nem lehet negatív érték'} :{isValid: true }           
                    } ,
                    { 
                        title: 'Plus bér megjegyzés', 
                        field: 'notes', 
                        type: 'string'                    }              
                    ]}
                options={{
                    paging: false,
                    rowStyle: rowData => {
                        if(rowData.isDayOff) {
                            return {backgroundColor: '#ffff77'};
                        }
                        if(rowData.isSickPay) {
                            return {backgroundColor: '#ff6347'};
                        }
                        if(rowData.isNotPayedDayOff) {
                            return {backgroundColor: '#ffa805'};
                        }
                        if(rowData.travellingState == 'Driver') {
                            return {backgroundColor: '#00cc00'};
                        }
                        if(rowData.travellingState == 'Passenger') {
                            return {backgroundColor: '#98FB98'};
                        }
                        if(rowData.isNotPayedDayOff) {
                            return {backgroundColor: '#ffa805'};
                        }            
                        if(rowData.isDuplicate) {
                          return {backgroundColor: '#ffffff'};
                        }
                        return {backgroundColor: '#f7f7f7'}                 
                      }
                }}
                data={workDays}
                initialFormData={workDays}
                totalCount={31}
                actions={[
                    {
                        icon: 'add',
                        tooltip: 'Add User',
                        isFreeAction: true,
                        hidden: true
                    },
                    rowData => ({
                        icon: 'library_add',
                        tooltip: 'Sor duplikálása',
                        disabled: rowData.isSickPay || rowData.isDayOff,
                        onClick: (event, rowData) => {
                                handleDuplicateWorkDay(rowData.id)
                        }
                    }),
                    rowData => ({
                        icon: 'beach_access',
                        tooltip: 'Szabad nap',
                        disabled: rowData.isDuplicate,
                        onClick: (event, rowData) => {
                                handleMarkWorkdayAsDayOff(rowData.id)
                        }
                    }),
                    rowData => ({
                        icon: 'local_pharmacy',
                        tooltip: 'Táppénz',
                        disabled: rowData.isDuplicate,
                        onClick: (event, rowData) => {
                            handleMarkWorkdayAsSickPay(rowData.id)
                        }
                    }),
                    rowData => ({
                        icon: 'money_off',
                        tooltip: 'Nem fizetett szabadság',
                        disabled: rowData.isDuplicate,
                        onClick: (event, rowData) => {
                            handleMarkWorkdayAsNotPayedDayOff(rowData.id)
                        }
                    }),
                    rowData => ({
                        icon: 'car_rental',
                        tooltip: 'Utazás sofőrként',
                        onClick: (event, rowData) => {
                            handleMarkWorkdayAsTravellingAsDriver(rowData.id)
                        }
                    }),
                    rowData => ({
                        icon: 'directions_car',
                        tooltip: 'Utazás utasként',
                        onClick: (event, rowData) => {
                            handleMarkWorkdayAsTravellingAsPassenger(rowData.id)
                        }
                    }),
                    rowData => ({
                        icon: 'delete',
                        tooltip: 'Sor törlése',
                        onClick: (event, rowData) => {
                                handleDeleteWorkDay(rowData.id)
                        }                    
                    })
                ]}
                
                editable={{
                    editTooltip : rowData => "Szerkesztés",
                    isEditable : rowData => (!rowData.isDayOff && !rowData.isSickPay),
                    isDeletable: rowData => rowData.isDuplicate == true,
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            handleRowUpdate(newData, oldData, resolve);
                        }),
                    onRowAdd: (rowData) =>
                        new Promise((resolve, reject) => {
                            resolve();
                    })
                    /*onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            handleDeleteRow(oldData)
                            resolve()
                        }),  */
                }}
            >
            </MaterialTable>
            <PaymentSummary employeeId = {employee?.id} year = {year} month = {month} workDays = {workDays}/>
            {/*Summary row soon from: https://github.com/mbrn/material-table/pull/2508*/}
        </div>
    )
}


export default EmployeeWorksheet