export interface ITypeTranslation {
  translation: string;
}

export class ErrorCodesTranslator {
  static translations: { [id: string]: ITypeTranslation } = {
    ProductNameAlreadyReserved: { translation: "Egy másik termék már rendelkezik a megadott termék névvel" },
    ProductHasNotEnoughQuantity: { translation: "A terméknek nincs elég mennyisége a művelet elvégzéséhez" },
    QuantityMustBeGreaterOrEqualToZero: {
      translation: "A mennyiségnek nagyobbnak vagy egyenlőnek kell lenni nullával",
    },
    EmployeeHasNoCarTypeSpecified: { translation: "Az alkalmazottnak nincs a kocsi tipusa megadva." },
    UnexpectedError: { translation: "Nem várt szerver hiba" },
    undefined: { translation: "Nem forditott hiba. Kérjük jelezzen a rendszergazdának." },
  };

  translate(errorCode: string): string {
    try {
      return ErrorCodesTranslator.translations[errorCode].translation;
    } catch (error) {
      var errorMessage = `The errorCode ${errorCode} can not be translated because there is not corresponding translation specified`;
      console.log(errorMessage);
      throw new TypeError(errorMessage);
    }
  }
}
