import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { Formik, Form } from "formik";
import * as yup from "yup";
import {CurrencyNumberFormat} from 'Core/Components/Form/CurrencyNumberFormat'
import { Select } from "material-ui-formik-components";
import {Field } from 'formik';

import CarTypes from '../CarTypes'
import {CarTypeTranslator} from '../CarTypeTranslator'
import {IEmployee} from '../types'

const isRequiredText = (name: any) => name + " megadása kötelező"

let SignupSchema = yup.object().shape({
  name: yup
    .string()
    .required(isRequiredText("Név"))
    .max(255, "A Név nem lehet nagyobb 255 karakternél"),
  email: yup
    .string()
    .email("Az e-mail cím nem megfelelő")
    .max(100, "Az e-mail nem lehet nagyobb 100 karakternél"),
  phone : yup
    .string()
    .max(20, "A telefonszám nem lehet nagyobb 20 karakternél"),
  generalHourlyWage: yup
    .number()
    .min(0, "Az általános órabér nem lehet negatív érték"),
    bigWorkHourlyWage: yup
    .number()
    .min(0, "A nagymunka órabér nem lehet negatív érték")
  });

interface IEmployeeFormProps {
    handleSubmit : Function
    initialValues? : IEmployee
}

const EmployeeForm = (props : IEmployeeFormProps) => {
  const carTypeTranslator = new CarTypeTranslator();

  var initEmployee : IEmployee = {
          name: props.initialValues ? props.initialValues.name : "",
          phone: props.initialValues ? props.initialValues.phone : "",
          email: props.initialValues ? props.initialValues.email : "",
          generalHourlyWage: props.initialValues ? props.initialValues.generalHourlyWage : 0,
          bigWorkHourlyWage: props.initialValues ? props.initialValues.bigWorkHourlyWage : 0,
          carType: props.initialValues ? props.initialValues.carType : "",
          carFuelConsumptionPer100km: props.initialValues ? props.initialValues.carFuelConsumptionPer100km : null,
          notes : props.initialValues ? props.initialValues.notes : ""
        };
  
  return (
    <div style={{width: 300}}>
    <Formik
        initialValues={initEmployee}
        validationSchema={SignupSchema}
        onSubmit={(values, {resetForm}) => {
          let employee = JSON.parse(JSON.stringify(values)) as IEmployee
          employee.id = props.initialValues ? props.initialValues.id : "";
          
          props.handleSubmit(employee)
          resetForm();
        }}
      >
        {({ errors, handleChange, touched,values }) => (
          <Form>
            <TextField
              error={Boolean(touched.name) && Boolean(errors.name)}
              autoComplete="fname"
              name="name"
              value={values.name}
              fullWidth
              onChange={handleChange}
              id="name"
              label="Név"
              autoFocus
              helperText={
                errors.name && touched.name
                  ? errors.name
                  : null
              }
            />

            <TextField
              error={Boolean(touched.phone) && Boolean(errors.phone)}
              autoComplete="fname"
              name="phone"
              value={values.phone}
              fullWidth
              onChange={handleChange}
              id="phone"
              label="Telefonszám"
              helperText={
                errors.phone && touched.phone
                  ? errors.phone
                  : null
              }
            />
            <TextField
              error={Boolean(errors.email) && Boolean(touched.email)}
              fullWidth
              onChange={handleChange}
              value={values.email }
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              helperText={
                errors.email && touched.email ? errors.email : null
              }
            />
            <TextField
              id="generalHourlyWage"
              name="generalHourlyWage"
              label="Általános órabér" 
              value={values.generalHourlyWage}
              onChange={handleChange}
              InputProps={{
                inputComponent: CurrencyNumberFormat,
              }}
              fullWidth
            />
            <TextField
              id="bigWorkHourlyWage"
              name="bigWorkHourlyWage"
              label="Nagymunka órabér" 
              value={values.bigWorkHourlyWage}
              onChange={handleChange}
              InputProps={{
                inputComponent: CurrencyNumberFormat,
              }}
              fullWidth
            />
            <Field
              required
              name="carType"
              label="Kocsi típus"
              options={CarTypes.getAll().map(type => ({value: type, label: carTypeTranslator.translate(type)}))}
              component={Select}
            />
            <TextField
              id="carFuelConsumptionPer100km"
              name="carFuelConsumptionPer100km"
              type="number"
              helperText={touched.carFuelConsumptionPer100km ? errors.carFuelConsumptionPer100km : ""}
              error={touched.carFuelConsumptionPer100km && Boolean(errors.carFuelConsumptionPer100km)}
              label="Kocsi fogyasztás (liter/100km)"
              value={values.carFuelConsumptionPer100km}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              id="notes"
              name="notes"
              label="Megjegyzés"
              value={values.notes}
              onChange={handleChange}
              fullWidth
              multiline
              rowsMax={4}
              />
            <div className="emptyLine"> </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Mentés
            </Button>
          </Form>
        )}
      </Formik>
      </div>
      )
  }

export default EmployeeForm