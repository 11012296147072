
import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';


export default function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.address, query);
  const parts = parse(suggestion.address, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 700 }}>{/*https://dev.to/sage911/how-to-write-a-search-component-with-suggestions-in-react-d20*/}
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}