import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuList from '@material-ui/core/MenuList';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CardMedia from '@material-ui/core/CardMedia';
import LoginBar from './LoginBar'

import './MainFrame.css'


import { NavLink } from 'react-router-dom';

import routes from './RoutesDefinitions'

import Logo from './kali-gaz-logo.png';
import styles from './MainFrame.style.js';

class MainFrame extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = { 
      openByButton: true,
      open: true, 
      openedMenuIndex: null,
      activeMenuIndex: null,
      activeMenuTitle: ""};
  }
  
  componentDidMount() {
    this.setActiveMenu(1, routes[0].navbarName);
  }

  handleMenuClickForOpeningSubMenu = (menuIndex) => {
    if(this.state.openedMenuIndex === menuIndex) {
      this.setState(state => ({ 
        openedMenuIndex: null}));
    } else {
      this.setState(state => ({ 
        openedMenuIndex: menuIndex}));
    }
  };


  handleDrawerOpenByButton = () => {
    this.setState({ openByButton: true });
    this.handleDrawerOpen()
  };

  handleDrawerCloseByButton = () => {
    this.setState({ openByButton: false });
    this.setState({ open: false });
  };


  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    if(!this.state.openByButton) {
      this.setState({ open: false });
    }
  };

  setActiveMenu = (clickedMenuIndex, activeMenuTitle) => {
    this.setState({ activeMenuTitle: activeMenuTitle });
    this.setState({ activeMenuIndex: clickedMenuIndex });
  };

  getStyle = (clickedMenuIndex) => (clickedMenuIndex === this.state.activeMenuIndex)
    ? 'listItemActive' 
    : 'listItem';

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar 
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpenByButton}
              className={classNames(classes.menuButton, {
                [classes.hide]: this.state.open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="Close drawer"
              onClick={this.handleDrawerCloseByButton}
              className={classNames(classes.menuButton, {
                [classes.hide]: !this.state.open,
              })}
            >
              <MoreVertIcon />
            </IconButton>
            <div className={classes.menuTitle}> {this.state.activeMenuTitle} </div>
            <LoginBar style={{textAlign: "right"}} />
          </Toolbar>
        </AppBar>
        <Drawer
          onMouseOver={this.handleDrawerOpen}
          onMouseLeave={this.handleDrawerClose}
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <Typography variant="h6" align="left" noWrap className={classNames(classes.title, {[classes.hide]: !this.state.open})}>
              Káli GázSzervíz 
            </Typography>
            <div className="logo">
              <img src={Logo} width="50" height="50" alt="logo kép"/>
            </div>

          <CardMedia src={Logo}></CardMedia>
          </div>
          <Divider className={classes.whiteBg}/>
          
            <MenuList className={classes.list}>
              {routes.map((prop, key) => {
                return prop.subMenus.length === 0 
                ?
                <NavLink 
                  key={key}
                  exact to={prop.path}
                  style={{ textDecoration: 'none' }} 
                  onClick={e => this.setActiveMenu(prop.menuIndex, prop.navbarName)}> 
                    <ListItem className={this.getStyle(prop.menuIndex)}>
                      <ListItemIcon > <prop.icon className={classes.white} /> </ListItemIcon>
                      <ListItemText classes={{ primary: classes.menuItem }} primary={prop.navbarName} />
                    </ListItem>
                </NavLink> 
                :
                <div key={key}>
                  <ListItem className={this.getStyle(prop.menuIndex)}  onClick={e => this.handleMenuClickForOpeningSubMenu(prop.menuIndex)} >
                    <ListItemIcon > <prop.icon className={classes.white} /> </ListItemIcon>
                    <ListItemText classes={{ primary: classes.menuItem }} primary={prop.navbarName} />
                    {this.state.openedMenuIndex === prop.menuIndex ? <ExpandLess className={classes.white} /> : <ExpandMore className={classes.white} />}
                  </ListItem>                 
                  <Collapse in={this.state.openedMenuIndex === prop.menuIndex} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding className={classes.subMenuList}>
                  {prop.subMenus.map((submenuProp, key) =>{return ( 
                    <NavLink 
                      key={key}
                      exact to={submenuProp.path}
                      style={{ textDecoration: 'none' }} 
                      onClick={e => this.setActiveMenu(submenuProp.menuIndex, submenuProp.navbarName)}> 
                        <ListItem className={this.getStyle(submenuProp.menuIndex)}>
                          <ListItemIcon > <submenuProp.icon className={classes.white} /> </ListItemIcon>
                          <ListItemText classes={{ primary: classes.menuItem }} primary={submenuProp.navbarName} />
                        </ListItem>
                  </NavLink> 
                  )})}
                  </List>
                </Collapse> 
              </div>
              })}
            </MenuList>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar}/>
          <div>{this.props.children}</div>
        </main> 
      </div>
    );
  }
}

MainFrame.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MainFrame);
