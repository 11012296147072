import * as React from 'react';
import {useState, useEffect} from 'react';
import urlJoin from 'url-join'
import {REACT_APP_WEBAPP_URL} from 'Envs'
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {REACT_APP_AUTH_URL} from 'Envs'
import {REACT_APP_EXAMPLE_API_URL} from 'Envs'

import {ApplicationState} from 'Core/Services/Store/storeData';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {User} from 'oidc-client';
import {get, post} from 'Core/Utils/api'
import { RouteComponentProps } from 'react-router-dom';
import {handleError} from 'Core/Utils/ApiErrorHandler' 
import { toast } from 'react-toastify';


type AdminServiceProps = { user: User } & RouteComponentProps<{}>;

const Dashboard = (props : AdminServiceProps) => {
  var migrations = Array<string>();
  const [apiResult, setApiResult] = useState('');
  const [pendingMigrations, setPendingMigrations] = useState<Array<string>>(migrations);

  var valuesEndpoint = urlJoin(REACT_APP_EXAMPLE_API_URL(), 'values');
  const callApi = () => {
    get(valuesEndpoint)
      .then((response: any) => {
        console.log(response);
        setApiResult(JSON.stringify(response.data, null, 2));
      })
      .catch(error => {
        setApiResult(`${error}:`);
        handleError(error, "Hiba az example api hivasa kozben");
      });
  };

  var pendingMigrationsEndpoint = urlJoin(REACT_APP_WAREHOUSING_URL(), 'migrations');
  const getPendingMigrations = () => {
    get(pendingMigrationsEndpoint)
      .then((response: any) => {
        setPendingMigrations(response.data)
      })
      .catch(error => {
        handleError(error, "Hiba a migration api endpoint hivasa kozben");
      });
  };

  var applyMigrationsEndpoint = urlJoin(REACT_APP_WAREHOUSING_URL(), 'migrations');
  const applyNewMigrations = () => {
    post(applyMigrationsEndpoint)
    .then(response => {
      toast.success(`The migration script(s) have been applied`)
      getPendingMigrations()
    })
    .catch(error => {
      handleError(error, 'There is an error happened during executing migration scripts')
    });
  };

  
  var generateWorksheetsEndpoint = urlJoin(REACT_APP_WAREHOUSING_URL(), 'employees', 'generateworksheets');
  const generateWorksheets = () => {
    get(generateWorksheetsEndpoint)
    .then(response => {
      toast.success(`The worksheets have been generated`)
      getPendingMigrations()
    })
    .catch(error => {
      handleError(error, 'There is an error happened during generating worksheets')
    });
  };

  //OnComponentDidMount
  useEffect(() => {
    getPendingMigrations();
  }, []);

  return (
    <div className="p-centered">
      <h3>Funkciók tesztelése: </h3>
       <div className="empty-action">
          <button className="btn btn-primary" onClick={() => callApi()}>
            Test API
          </button>
      </div>
      <div className="empty-action">
          <button className="btn btn-primary" onClick={() => generateWorksheets()}>
            Generate worksheets
          </button>
      </div>
      <pre className="code">
        <code>{apiResult}</code>
      </pre>
      <h3>Env variables: </h3>
      <div className="empty">
        <p className="empty-title h5">REACT_APP_WEBAPP_URL: {REACT_APP_WEBAPP_URL()}</p>
        <p className="empty-title h5">REACT_APP_WAREHOUSING_URL: {REACT_APP_WAREHOUSING_URL()}</p>
        <p className="empty-title h5">REACT_APP_AUTH_URL: {REACT_APP_AUTH_URL()}</p>
        <p className="empty-title h5">REACT_APP_EXAMPLE_API_URL: {REACT_APP_EXAMPLE_API_URL()}</p>
      </div>
      <h3>User info: </h3>
      <div className="empty">
        <p className="empty-title h5">access_token: {props.user ? props.user.access_token : "NA"} </p>
      </div>
      <h3>Migrations: </h3>
      <div className="empty-action">
          { pendingMigrations!.length > 0 
          ? (<button className="btn btn-primary" onClick={() => applyNewMigrations()}>
              Migrate warehousing database
            </button>)
          : (<div> </div>)
          }          
      <pre className="code">
        {pendingMigrations?.map(migration  => <p key={migration}>{migration}</p>)}
      </pre>
      </div>
    </div>
  );
};

function mapStateToProps(state: ApplicationState) {
  return {
    user: state.oidc.user
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);