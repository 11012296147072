var WorkPaymentCategories = {
    GENERAL: "General",
    BIGWORK: "BigWork",

    getAll: () =>  {
      return [
        WorkPaymentCategories.GENERAL, 
        WorkPaymentCategories.BIGWORK
      ]
    }
};

export default WorkPaymentCategories