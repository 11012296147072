export interface ITypeTranslation {
    translation: string;
 }

export class UnitTranslator {
    static translations: { [id: string]: ITypeTranslation; } = {
        "Piece": { translation: "darab" },
        "RunningMeter": { translation: "folyóméter" },
        "Liter": { translation: "liter" },
        "Package": { translation: "csomag" }
     };


    translate(type: string) : string {
        try {
            return UnitTranslator.translations[type].translation 
        } catch (error) {
            throw new TypeError(`The unit ${type} can not be translated because there is not corresponding translation specified`)
        }
    }
}

