import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { Formik, Form } from "formik";
import * as yup from "yup";
import {CurrencyNumberFormat} from 'Core/Components/Form/CurrencyNumberFormat'
import { Select } from "material-ui-formik-components";
import {Field } from 'formik';

import CarTypes from './CarTypes'
import {CarTypeTranslator} from './CarTypeTranslator'
import {IWorkSheetDetails} from './types'

const isRequiredText = (name: any) => name + " megadása kötelező"

let WorksheetDetailsSchema = yup.object().shape({
  fuelPrice: yup
    .number()
    .min(0, "Az benzin ára nem lehet negatív érték"),
  dieselPrice: yup
      .number()
      .min(0, "A dízel ára nem lehet negatív érték"),
  generalHourlyWage: yup
    .number()
    .min(0, "Az általános órabér nem lehet negatív érték"),
  bigWorkHourlyWage: yup
      .number()
      .min(0, "A nagymunka órabér nem lehet negatív érték")
  });

interface IWorksheetDetailsFormProps {
    handleSubmit : Function
    initialValues? : IWorkSheetDetails
}

const WorksheetDetailsForm = (props : IWorksheetDetailsFormProps) => {
  const carTypeTranslator = new CarTypeTranslator();

  var initWorksheetDetails : IWorkSheetDetails = {
          fuelPrice: props.initialValues ? props.initialValues.fuelPrice : 0,
          dieselPrice: props.initialValues ? props.initialValues.dieselPrice : 0,
          generalHourlyWage: props.initialValues ? props.initialValues.generalHourlyWage : 0,
          bigWorkHourlyWage: props.initialValues ? props.initialValues.bigWorkHourlyWage : 0,
          carType: props.initialValues ? props.initialValues.carType : "",
          carFuelConsumptionPer100km: props.initialValues ? props.initialValues.carFuelConsumptionPer100km : null,
        };
  
  return (
    <div style={{width: 300}}>
    <Formik
        initialValues={initWorksheetDetails}
        validationSchema={WorksheetDetailsSchema}
        onSubmit={(values, {resetForm}) => {
          let worksheetDetails = JSON.parse(JSON.stringify(values)) as IWorkSheetDetails
          
          props.handleSubmit(worksheetDetails)
          resetForm();
        }}
      >
        {({ errors, handleChange, touched,values }) => (
          <Form>
            <TextField
              id="generalHourlyWage"
              name="generalHourlyWage"
              label="Általános órabér" 
              value={values.generalHourlyWage}
              onChange={handleChange}
              InputProps={{
                inputComponent: CurrencyNumberFormat,
              }}
              fullWidth
            />
            <TextField
              id="bigWorkHourlyWage"
              name="bigWorkHourlyWage"
              label="Nagymunka órabér" 
              value={values.bigWorkHourlyWage}
              onChange={handleChange}
              InputProps={{
                inputComponent: CurrencyNumberFormat,
              }}
              fullWidth
            />
             <TextField
              id="fuelPrice"
              name="fuelPrice"
              label="Benzin ára" 
              value={values.fuelPrice}
              onChange={handleChange}
              InputProps={{
                inputComponent: CurrencyNumberFormat,
              }}
              fullWidth
            />
            <TextField
              id="dieselPrice"
              name="dieselPrice"
              label="Dízel ára" 
              value={values.dieselPrice}
              onChange={handleChange}
              InputProps={{
                inputComponent: CurrencyNumberFormat,
              }}
              fullWidth
            />
            <Field
              required
              name="carType"
              label="Kocsi típus"
              options={CarTypes.getAll().map(type => ({value: type, label: carTypeTranslator.translate(type)}))}
              component={Select}
            />
            <TextField
              id="carFuelConsumptionPer100km"
              name="carFuelConsumptionPer100km"
              type="number"
              helperText={touched.carFuelConsumptionPer100km ? errors.carFuelConsumptionPer100km : ""}
              error={touched.carFuelConsumptionPer100km && Boolean(errors.carFuelConsumptionPer100km)}
              label="Kocsi fogyasztás (liter/100km)"
              value={values.carFuelConsumptionPer100km}
              onChange={handleChange}
              fullWidth
            />
            <div className="emptyLine"> </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Mentés
            </Button>
          </Form>
        )}
      </Formik>
      </div>
      )
  }

export default WorksheetDetailsForm