import * as React from 'react';
import {connect} from 'react-redux';
import {ApplicationState} from '../Services/Store/storeData';
import {Dispatch} from 'redux';
import {User} from 'oidc-client';

import userManager from '../Services/Authentication/Logic/userManager';
import Button from "@material-ui/core/Button";
import AccountIcon from '@material-ui/icons/AccountCircle';
import Exit from '@material-ui/icons/ExitToApp';

import {MuiThemeProvider} from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import {useState} from 'react';
import { toast } from 'react-toastify';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#FF4500',
      main: '#FF4500',
      dark: '#FF4500'
    },
    secondary: {
      light: '#007BFF',
      main: '#007BFF',
      dark: '#007BFF',
    }
    },
});

interface LoginBarProps {
  user : User;
  path: string;
}

const Home = (props : LoginBarProps) => { 
  //Sample for react hook example 
  const [apiResult, setApiResult] = useState('');

  const logout = (event: any) => {
    event.preventDefault();
    userManager.signoutRedirect()
    .catch(error => {
      toast.error(`Nem várt hiba kijelentkezés közben: ${error}`)
    });
    userManager.removeUser();
  };

  const login = () => {
      // pass the current path to redirect to the correct page after successfull login
      userManager.signinRedirect({
        data: {path: props.path},
      })
      .catch(error => {
        toast.error(`Nem várt hiba bejelentkés közben: ${error}`)
      });
  };

  const userIsConnected: boolean = !!props.user;
  const userName = userIsConnected ? props.user.profile.name : null;
  return (
    <MuiThemeProvider theme = {theme}>
      <div style={{ display: "flex", width: "100%" }}>
          <div style={{  marginLeft: "auto", marginRight: "10px"}}>
          {userIsConnected ? (
                <Button 
                    type="submit" 
                    variant="contained" 
                    color="secondary" 
                    onClick={event => logout(event)}>
                        {userName}
                        <Exit style = {{marginLeft: 10}}/> 
                </Button>
              ) : (
              <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary"
                  onClick={() => login()}>
                      Bejelentkezés
                      <AccountIcon style = {{marginLeft: 10}}/> 
            </Button>
            )}
            <pre className="code">
              <code>{apiResult}</code>
            </pre>
          </div>   
        </div>
      </MuiThemeProvider>
    )
};

function mapStateToProps(state: ApplicationState) {
    return {
      user: state.oidc.user,
      path : state.router.location.pathname
    };
  }
  
  function mapDispatchToProps(dispatch: Dispatch) {
    return {
      dispatch,
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home);
  
