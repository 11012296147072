import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import WorkForm from './WorkForm'
import {IWork} from '../../types'
import handleAddNewWorkSubmit from './handleAddNewWorkSubmit'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop : theme.spacing(3)
  }
}));

interface AddWorkDialogProps {
    customerId: string
    defaultAddress: string
    refreshWorks: Function
  }

export const AddWorkDialog = (props : AddWorkDialogProps) =>  {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button 
        type="submit"
        className={classes.button} 
        variant="contained"
        color="secondary"
        disabled= {props.customerId === ''} 
        onClick={handleClickOpen} >
           Új munka hozzáadása 
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Új munka hozzáadása"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <WorkForm 
                handleSubmit={(customerId: string, newWork: IWork) => {
                  handleAddNewWorkSubmit(customerId, newWork, props.refreshWorks)
                  handleClose();
                }} 
                initialValues={{
                  address: props.defaultAddress, 
                  customerId: props.customerId,
                  surveyDate: null,
                  type:"",
                  paymentCategory: "" 
                  }} />
          </DialogContentText >
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}