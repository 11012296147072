var Units = {
    Piece: "Piece",
    RunningMeter: "RunningMeter",
    Liter: "Liter",
    Package: "Package",

    getAll: () =>  {
      return [
        Units.Piece, 
        Units.RunningMeter, 
        Units.Liter, 
        Units.Package, 
      ]
    }
};

export default Units