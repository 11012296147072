import {post} from 'Core/Utils/api'

import urlJoin from 'url-join'
import { toast } from 'react-toastify';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {handleError} from 'Core/Utils/ApiErrorHandler';
const handleNewEmployeeSubmit = (newEmployee: any) => {
  var warehousingUrl = REACT_APP_WAREHOUSING_URL();

  post(urlJoin(warehousingUrl, 'employees'), newEmployee)
    .then(response => {
      toast.success(`Az új alkalmazott ${newEmployee.name} sikeresen hozzá lett adva a raktárhoz`)
      console.log(response);
    })
    .catch(error => {
      handleError(error, 'Hiba történt az új alkalmazott hozzáadása közben')
    });
}

export default handleNewEmployeeSubmit
