import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    expansionPanel: {
      width: '1400px'
    },
    expansionDetail: {
      display: 'block'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    title: {
      fontSize: 14,
    },
    basketWrapper: { 
      width: 1200, 
      border: '1px solid', 
      borderColor:'	#E0E0E0', 
      borderRadius:'10x', 
      marginTop: 20, 
      padding:10, 
      backgroundColor: '#F5F5F5'
    },
    statusSurveyAppointmentMaking : {
      background: 'rgba(191, 191, 191, 0.2)' //grey
    },
    statusQuotationMaking: {
      background: 'rgba(255, 255, 126, 0.2)' //yellow
    },
    statusImplementationAppointmentMaking: {
      background: 'rgba(34, 167, 240, 0.2)' //blue 
    },
    statusBeingImplemented: {
      background: 'rgba(0, 191, 255, 0.3)' //Deep sky blue
    },
    statusUnderPayment: {
      background: 'rgba(242, 38, 19, 0.2)' //red
    },
    statusClosed: {
      background: 'rgba(50,205,50, 0.2)' //green
    },
  }),
);

export const getWorkStatusStyle = (classes: any, status : string) => {
    switch(status) { 
      case 'SurveyAppointmentMaking': {  
        return classes.statusSurveyAppointmentMaking
      } 
      case 'QuotationMaking': { 
        return classes.statusQuotationMaking
      }
      case 'ImplementationAppointmentMaking': { 
        return classes.statusImplementationAppointmentMaking
      }
      case 'BeingImplemented': { 
        return classes.statusBeingImplemented
      }
      case 'UnderPayment': { 
        return classes.statusUnderPayment
      }  
      case 'Closed': { 
        return classes.statusClosed
      }  
      default: { 
         break;
      } 
  }
}