import React from 'react';
import classNames from 'classnames';
import NewProductFormPage from './NewProductFormPage'
import handleNewProductSubmit from './handleNewProductSubmit'

const InputFormWrapper = () => 
  <div className={classNames("widget")} style={{width: 270}}> 
    <NewProductFormPage
      resetForm = {true} 
      handleSubmit = {(product: any) => handleNewProductSubmit(product)}/> 
  </div>

  export default InputFormWrapper