import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ContactPhone from '@material-ui/icons/ContactPhone';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import EmployeeInfoTab from './EmployeeInfo/EmployeeInfoTab'
import VacationDaysTab from './EmployeeInfo/VacationDaysTab'
import classNames from 'classnames';
import EmployeeSearchAutoSuggest from './EmployeeSearchAutoSuggest'
import TabPanel from './TabPanel'
import {IEmployee} from '../types'
import PeopleList from 'Core/Components/PeopleList'

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  employeeList : {
    margin: 10,
  },
  employeeListAndDetails:  {
    display: 'flex',
    flexDirection: 'row'
}
}));

const CustomerManagerState = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [employeeData, setEmployeeData] = useState<IEmployee>({
    id: '',
    name: '',
    phone: '',
    email: '',
    generalHourlyWage: 0,
    bigWorkHourlyWage : 0,
    carType : '',
    carFuelConsumptionPer100km: null,
    notes : ''
  });

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.employeeListAndDetails}>
      <div className={classes.employeeList}>
        <PeopleList height = {550}  listEndpointRoute= 'employees' setSelectedPerson = {setEmployeeData}/>
      </div>
      <div className={classNames(classes.root, 'widget')}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Alkamazott adatai" icon={<ContactPhone />} {...a11yProps(0)} />
          <Tab label="Szabadnapok" icon={<BeachAccessIcon />} {...a11yProps(1)} />
        </Tabs>
        <EmployeeInfoTab value={value} index={0} employeeData= {employeeData} setEmployeeData = {setEmployeeData}/>
        <VacationDaysTab value={value} index={1} employeeId= {employeeData.id}/>
      </div>
    </div>
  );
}

export default CustomerManagerState