import urlJoin from 'url-join'
import  {QuantityIncrementerFormValues} from "./ProductQuantityIncrementerForm";
import { toast } from 'react-toastify';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {patch} from 'Core/Utils/api'
import {handleError} from 'Core/Utils/ApiErrorHandler';



const handleProductQuantityChangeSubmit = (product: QuantityIncrementerFormValues) => {
  console.log(product);
  let node = {
    "quantity" : product.quantity
  }

  var productUrl = urlJoin(REACT_APP_WAREHOUSING_URL(), 'products', product.productId.toString());
  
  patch(productUrl,node)
    .then((success) => {
      toast.success("Az termék mennyiség módosítása sikeresen megtörtént")
      console.log(success);
    }).catch((error) => {
      handleError(error, "Hiba a termék mennyiségének módosítása közben")
    })
}

export default handleProductQuantityChangeSubmit
