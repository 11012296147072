import NumberFormat from 'react-number-format'
import React from 'react';

interface IPriceInHUFComponentProps{
  price : number
}

const PriceInHUFComponent = (props: IPriceInHUFComponentProps) => {
    return(
        <NumberFormat
        displayType={'text'}
        value = {props.price}
        thousandSeparator
        suffix = " Ft"
        decimalScale = {0}
        allowNegative = {false}
      />
    )
}

export default PriceInHUFComponent;