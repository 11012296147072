import * as React from 'react';
import { useState, useEffect} from 'react';

import TabPanel from '../TabPanel'
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {IEmployee} from '../../types'
import {ITab} from '../../types'
import isEmpty from 'lodash/isEmpty';
import {EditEmployeeDialog} from './EditEmployeeDialog'
import {DeleteEmployeeDialog} from './DeleteEmployeeDialog'
import VacationDaysForm from './VacationDaysForm'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 14,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    }
  }),
);

interface IVacationDaysTabProps {
  employeeId : string
}

const VacationDaysTab = (props : IVacationDaysTabProps & ITab) => { 
  const [year, setYear]  = useState(new Date().getFullYear());

  const handleYearChange = (event : any) => {
    setYear(event.target.value);
  };

  const classes = useStyles();

  if(!props.employeeId){
    return (<div/>)
  }
  return (
    <TabPanel value={props.value} index={props.index}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Év</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={year}
            onChange={handleYearChange}
            >
            <MenuItem value={2019}>2019</MenuItem>
            <MenuItem value={2020}>2020</MenuItem>
            <MenuItem value={2021}>2021</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2024}>2024</MenuItem>
        </Select>
    </FormControl>
      <VacationDaysForm year = {year} employeeId = {props.employeeId}/>
    </TabPanel>
    )
};

export default VacationDaysTab

