import {post} from 'Core/Utils/api'

import urlJoin from 'url-join'
import { toast } from 'react-toastify';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {handleError} from 'Core/Utils/ApiErrorHandler';

const handleNewCustomerSubmit = async (customerId : any, newWork: any, refreshWorks : Function) => {
  var warehousingUrl = REACT_APP_WAREHOUSING_URL();
  
  post(urlJoin(warehousingUrl, 'customers', customerId.toString(), 'works'), newWork)
    .then(response => {
      toast.success(`Az új munka sikeresen hozzá lett adva az ügyfélhez`)
      refreshWorks()
      console.log(response);
    })
    .catch(error => {
      handleError(error, 'Hiba történt az új munka hozzáadása közben')
    });
} 

export default handleNewCustomerSubmit
