import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import handleWorksheetDetailsSubmit  from "./handleEditWorksheetDetails";
import WorksheetDetailsForm  from "./WorksheetDetailsForm";
import {IWorkSheetDetails} from './types'
import {IWork} from '../CustomerManagement/types'


function IsArrayContainsData(array: any) {
  return Boolean(array.length)
}

const useStyles = makeStyles(theme => ({
    form : {
      width: '400px'
    }
    ,
    button: {
      marginTop:20,
      marginLeft:10, 
      textTransform: 'none'
  }
}));

interface IEditWorksheetDetailsDialogProps {
  employeeId: String
  year: number
  month: number
  workDays: Array<IWork>
  refreshWorkerShifts: any
  editWorksheetDetailsFormInitialValues? : IWorkSheetDetails
  setWorksheetDetailsData : Function
}

export const EditWorksheetDetailsDialog = (props : IEditWorksheetDetailsDialogProps) =>  {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button 
        type="submit"
        className={classes.button} 
        variant="contained"
        color="secondary"
        disabled={!IsArrayContainsData(props.workDays)}
        onClick={handleClickOpen} >
           Bérek/árak módosítása
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Munkanapló számitási adatok szerkesztése"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <WorksheetDetailsForm 
              handleSubmit = {(w : IWorkSheetDetails) => {
                handleWorksheetDetailsSubmit(props.employeeId,props.year,props.month, w);
                props.refreshWorkerShifts() 
                setOpen(false);
                props.setWorksheetDetailsData(w)
              }} 
              initialValues = {props.editWorksheetDetailsFormInitialValues}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}