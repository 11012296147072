import * as React from 'react';
import TabPanel from '../TabPanel'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {ICustomer} from '../../types'
import {ITab} from '../../types'
import isEmpty from 'lodash/isEmpty';
import {EditCustomerDialog} from './EditCustomerDialog'

const useStyles = makeStyles({
  title: {
    fontSize: 14,
  }
});

interface CustomerInfoProps {
  customerData : ICustomer
  setCustomerData : Function
}

const CustomerInfoTab = (props : CustomerInfoProps & ITab) => { 
  const classes = useStyles();

  return (
    <TabPanel value={props.value} index={props.index}>
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
        Név
        </Typography>
        <Typography variant="body1" gutterBottom>
        {isEmpty(props.customerData.name) ?  "-" : props.customerData.name}
        </Typography>
      </div>
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Cím
        </Typography>
        <Typography variant="body1" gutterBottom>
        {isEmpty(props.customerData.address) ? "-" : props.customerData.address}
        </Typography>
      </div>
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Telefonszám
        </Typography>
          <Typography variant="body1" gutterBottom>
          {isEmpty(props.customerData.phone) ? "-" : props.customerData.phone}
        </Typography>
      </div>
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          E-mail
        </Typography>
          <Typography variant="body1" gutterBottom>
          {isEmpty(props.customerData.email) ? "-" : props.customerData.email}
        </Typography>
      </div>
      <div>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Megjegyzés
        </Typography>
        <Typography variant="body1" gutterBottom>
        {isEmpty(props.customerData.notes)  ? "-" : props.customerData.notes}
        </Typography>
      </div>
      <EditCustomerDialog editCustomerFormInitialValues = {props.customerData} setCustomerData = {props.setCustomerData}/>
    </TabPanel>
    )
};

export default CustomerInfoTab

