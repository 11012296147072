import React, {useEffect, useState} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import urlJoin from 'url-join'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TabPanel from '../TabPanel'
import {ITab, ICustomer, IWork} from '../../types'
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {get, post} from 'Core/Utils/api'
import {handleError} from 'Core/Utils/ApiErrorHandler' 
import {WorkStatusTranslator} from '../../Core/WorkStatusTranslator'
import {WorkTypeTranslator} from './WorkTypeTranslator'
import {WorkPaymentCategoryTranslator} from './WorkPaymentCategoryTranslator'
import {AddWorkDialog} from './AddWorkDialog'
import {useStyles, getWorkStatusStyle} from './WorksTab.style'
import WorkStatusComponent from './WorkStatusComponent'
import QuoitationMaker from './Quotation/QuotationMaker'
import {EditWorkDialog} from './EditWorkDialog'

interface WorksTabProps {
  customerData : ICustomer
  setSelectedTab : Function
}

const WorksTab = (props: WorksTabProps & ITab) => {
  const defaultTabIndex = 0
  const statusTranslator = new WorkStatusTranslator()
  const workTypeTranslator = new WorkTypeTranslator()
  const workPaymentCategoryTranslator = new WorkPaymentCategoryTranslator()
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [works, setWorks] = useState<Array<IWork>>([]);

  var addressesEndpoint = urlJoin(REACT_APP_WAREHOUSING_URL(), 'customers', props.customerData.id.toString(), 'works');

  const getWorks = () => {
    if(props.customerData.id === '') {
      return
    }
    get(addressesEndpoint)
      .then((response: any) => {
        setWorks(response.data)
      })
      .catch(error => {
        handleError(error, "Hiba a munkák elővétele közben");
      });
  };

  useEffect(() => {
    getWorks();
    props.setSelectedTab(defaultTabIndex);
  }, [props.customerData]);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <TabPanel value = {props.value} index ={props.index}>
    <div className={classes.root}>
    {works!.map((work : IWork, key : any) => {
      return <ExpansionPanel className={classes.expansionPanel} expanded={expanded === work.id} key={key} onChange={handleChange(work.id)}>
          <ExpansionPanelSummary
            className = {getWorkStatusStyle(classes, work.status!)}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>{work.address}</Typography>
            <Typography className={classes.secondaryHeading}>{statusTranslator.translate(work.status!)}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionDetail}>
            <div>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Munka dátuma
              </Typography>
              <Typography variant="body1" gutterBottom>
              {work.surveyDate ? new Date(work.surveyDate).toLocaleDateString("hu-HU") : '-'}
              </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Tipus
              </Typography>
              <Typography variant="body1" gutterBottom>
              {work.type ? workTypeTranslator.translate(work.type) : '-'}
              </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Fizetési kategória
              </Typography>
              <Typography variant="body1" gutterBottom>
              {work.paymentCategory ? workPaymentCategoryTranslator.translate(work.paymentCategory) : '-'}
              </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Megjegyés
              </Typography>
              <Typography variant="body1" gutterBottom>
              {work.notes ? work.notes : '-'}
              </Typography>
              <WorkStatusComponent customerData = {props.customerData} workId = {work.id}/>
            </div>
            <EditWorkDialog customerId={props.customerData.id} editWorkFormInitialValues={work} refreshWorkData={getWorks}/>
            
            {/*(work.surveyDate !== null) 
              ? <QuoitationMaker  customerId = {props.customerData.id.toString()} workId = {work.id.toString()}/> 
              : <div></div>
            */}
          </ExpansionPanelDetails>
      </ExpansionPanel>
    })}
    <AddWorkDialog customerId = {props.customerData.id} defaultAddress = {props.customerData.address} refreshWorks = {getWorks}/>

    </div>
    </TabPanel>
  );
}

export default WorksTab