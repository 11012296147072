import {post} from 'Core/Utils/api'

import urlJoin from 'url-join'
import { toast } from 'react-toastify';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {handleError} from 'Core/Utils/ApiErrorHandler';

const handleNewCustomerSubmit = (newCustomer: any) => {
  var warehousingUrl = REACT_APP_WAREHOUSING_URL();
  
  post(urlJoin(warehousingUrl, 'customers'), newCustomer)
    .then(response => {
      toast.success(`Az új ügyfél ${newCustomer.name} sikeresen hozzá lett adva a raktárhoz`)
      console.log(response);
    })
    .catch(error => {
      handleError(error, 'Hiba történt az új ügyfél hozzáadása közben')
    });
}

export default handleNewCustomerSubmit
