import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";
import * as yup from "yup";
import {IWork} from '../../types'
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import {Field } from 'formik';
import WorkTypes from './WorkTypes'
import WorkPaymentCategory from './WorkPaymentCategory'
import {WorkTypeTranslator} from './WorkTypeTranslator'
import {WorkPaymentCategoryTranslator} from './WorkPaymentCategoryTranslator'
import { Select } from "material-ui-formik-components";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const isRequiredText = (name: any) => name + " megadása kötelező"

let SignupSchema = yup.object().shape({
    address: yup
      .string()
      .max(1000, "A cím nem lehet nagyobb 1000 karakternél")
      .required(isRequiredText("Cím")),
    type: yup
      .string()
      .required(isRequiredText("Típus"))
  });

const useStyles = makeStyles(theme => ({
    form : {
      width: '400px'
    }
    ,
    button: {
      marginTop : theme.spacing(3)
    }
}));

interface WorkFormProps {
    handleSubmit : Function
    initialValues? : IWork
}

const CustomerForm = (props : WorkFormProps) => {
  const classes = useStyles();
  const workTypeTranslator = new WorkTypeTranslator();
  const workPaymentCategoryTranslator = new WorkPaymentCategoryTranslator();
  
  return (
    <div style={{width: 300}}>
    <Formik
        initialValues={{
          customerId: props.initialValues ? props.initialValues.customerId : "", //This field is not used at the backend, only for fulfilling json-server responsibility
          address: props.initialValues ? props.initialValues.address : "",
          surveyDate: props.initialValues ? props.initialValues.surveyDate : undefined,
          notes: props.initialValues ? props.initialValues.notes : "",
          type: props.initialValues ? props.initialValues.type : "" ,
          paymentCategory: props.initialValues ? props.initialValues.paymentCategory : "" 
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, {resetForm}) => {
          let work = JSON.parse(JSON.stringify(values)) as IWork
          work.id = props.initialValues ? props.initialValues.id : "";
          
          props.handleSubmit(values.customerId, work)
          resetForm();
        }}
      >
        {({ errors, handleChange, touched,values, setFieldValue }) => (
          <Form>
            <TextField
                error={Boolean(touched.address) && Boolean(errors.address)}
                autoComplete="fname"
                name="address"
                value={values.address}
                fullWidth
                onChange={handleChange}
                id="address"
                label="Cím"
                autoFocus
                helperText={
                    errors.address && touched.address
                    ? errors.address
                    : null}
                    />
            <Field
              required
              name="type"
              label="Típus"
              options={WorkTypes.getAll().map(type => ({value: type, label: workTypeTranslator.translate(type)})).sort((a, b) => a.label.localeCompare(b.label))}
              component={Select}
            />
            <Field
              required
              name="paymentCategory"
              label="Fizetési kategória"
              options={WorkPaymentCategory.getAll().map(type => ({value: type, label: workPaymentCategoryTranslator.translate(type)})).sort((a, b) => a.label.localeCompare(b.label))}
              component={Select}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    id="date-picker-dialog"
                    label="Felmérés dátuma"
                    format="dd/MM/yyyy"
                    value={values.surveyDate}
                    error={Boolean(touched.surveyDate) && Boolean(errors.surveyDate)}
                    
                    onChange={value => setFieldValue("surveyDate", value?.toLocaleDateString("hu-HU"))}
                    KeyboardButtonProps={{
                        "aria-label": "change date"
                    }}
                    helperText={
                        errors.surveyDate && touched.surveyDate
                        ? errors.surveyDate
                        : null}
                    />
                </MuiPickersUtilsProvider>
 
                <TextField
                    error={Boolean(touched.notes) && Boolean(errors.notes)}
                    autoComplete="fname"
                    name="notes"
                    value={values.notes}
                    fullWidth
                    onChange={handleChange}
                    id="notes"
                    label="Megjegyzés"
                    helperText={
                        errors.address && touched.address
                        ? errors.address
                        : null
                }
                />
                <div className="emptyLine"> </div>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                >
                    Mentés
                </Button>
          </Form>
        )}
      </Formik>
      </div>
      )
  }

export default CustomerForm