import {SetDevEnvs} from 'EnvsForDevelopmentSetter'
if (process.env.NODE_ENV === 'development'){ 
    SetDevEnvs();
  }

const FormatEnv= (env: string) => env.trim();

export const REACT_APP_WEBAPP_URL = ()=> FormatEnv((window as any).REACT_APP_WEBAPP_URL); 
export const REACT_APP_WAREHOUSING_URL = ()=> FormatEnv((window as any).REACT_APP_WAREHOUSING_URL); 
export const REACT_APP_AUTH_URL = ()=> FormatEnv((window as any).REACT_APP_AUTH_URL); 
export const REACT_APP_EXAMPLE_API_URL = () => FormatEnv((window as any).REACT_APP_EXAMPLE_API_URL); 