import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ContactPhone from '@material-ui/icons/ContactPhone';
import HomeWork from '@material-ui/icons/HomeWork';
import LocalAtm from '@material-ui/icons/LocalAtm';
import CustomerInfoTab from './CustomerInfo/CustomerInfoTab'
import WorksTab from './Works/WorksTab'
import PaymentsTab from './Payments/PaymentsTab'
import classNames from 'classnames';
import CustomerSearchAutoSuggest from './CustomerSearchAutoSuggest'
import TabPanel from './TabPanel'
import {ICustomer} from '../types'
import PeopleList from 'Core/Components/PeopleList'

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  customerList : {
    margin: 10,
  },
  customersListAndDetails:  {
    display: 'flex',
    flexDirection: 'row'
}
}));

const CustomerManagerState = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState(0);

  const [customerData, setCustomerData] = useState<ICustomer>({
    id: '',
    name: '',
    address: '',
    phone: '',
    email: '',
    notes : ''
  });

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.customersListAndDetails}>
      <div className={classes.customerList}>
        <PeopleList height = {400} listEndpointRoute= 'customers' setSelectedPerson = {setCustomerData}/>
      </div> 
      <div className={classNames(classes.root, 'widget')}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Ügyfél adatok" icon={<ContactPhone />} {...a11yProps(0)} />
          <Tab label="Munkák" icon={<HomeWork />}{...a11yProps(1)} />
          <Tab label="Pénzügyek" icon={<LocalAtm />} {...a11yProps(2)} />
        </Tabs>
        <CustomerInfoTab value={selectedTab} index={0} customerData= {customerData} setCustomerData = {setCustomerData} />
        <WorksTab value={selectedTab} index={1} customerData= {customerData}  setSelectedTab = {setSelectedTab}/>
        <PaymentsTab value={selectedTab} index={2} customerData= {customerData} />
      </div>
    </div>
  );
}

export default CustomerManagerState