var CarTypes = {
    NO_CAR: "NoCar",
    PETROL: "Petrol",
    DIESEL: "Diesel",

    getAll: () =>  {
      return [
        CarTypes.NO_CAR, 
        CarTypes.PETROL, 
        CarTypes.DIESEL, 
      ]
    }
};

export default CarTypes