import DashBoardIcon from '@material-ui/icons/Dashboard';
import DomainIcon from '@material-ui/icons/Domain';
import SettingsIcon from '@material-ui/icons/Settings';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import TimerIcon from '@material-ui/icons/Timer';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ListIcon from '@material-ui/icons/List';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import PeopleAlt from '@material-ui/icons/PeopleAlt';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Person from '@material-ui/icons/Person';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';

import Dashboard from 'Dashboard/Dashboard';
import AdminService from 'Dashboard/AdminService';
import EmployeeWorksheet from 'EmployeeManagement/EmployeeWorksheet';
import AddNewEmployeePage from 'EmployeeManagement/NewEmployee/AddNewEmployeePage';
import EmployeeManagerPage from 'EmployeeManagement/EmployeeManager/EmployeeManagerPage';
import ProductsSearcher from 'Warehousing/ProductsSearcher';
import NewProductFormPage from 'Warehousing/NewProductPage/NewProductPageWrapper'
import ProductSearchWithDetailsPage from 'Warehousing/ProductDetailsPage/ProductSearchWithDetailsPage'
import AddNewCustomerPage from 'CustomerManagement/NewCustomer/AddNewCustomerPage'
import CustomerManagerPage from 'CustomerManagement/CustomerManager/CustomerManagerPage'
import GlobalSettingsPage from 'GlobalSettings/GlobalSettingsPage'

const Routes = [
  {
    path: '/',
    menuIndex: 1,
    navbarName: 'FŐOLDAL',
    icon: DashBoardIcon,
    component: Dashboard,
    subMenus: []
  },
  {
    path: '/',
    navbarName: 'RAKTÁR',   navbarName: 'RAKTÁR',   navbarName: 'RAKTÁR',   navbarName: 'RAKTÁR',   navbarName: 'RAKTÁR',   navbarName: 'RAKTÁR',
    menuIndex: 2,
    icon: DomainIcon,
    subMenus: [ 
      {    
        path: '/termekbongeszo',
        menuIndex: 21,
        navbarName: 'TERMÉK BÖNGÉSZŐ',
        icon: ListIcon,
        component: ProductsSearcher,
      },
      {    
        path: '/ujtermek',
        menuIndex: 22,
        navbarName: 'ÚJ TERMÉK',
        icon: PlaylistAdd,
        component: NewProductFormPage,
      },
      {    
        path: '/termekinfo',
        menuIndex: 23,
        navbarName: 'TERMÉK RÉSZLETEK',
        icon: ListAltIcon,
        component: ProductSearchWithDetailsPage,
      }
    ]
  },
  {
    path: '/',
    navbarName: 'ÜGYFÉLKEZELÉS',
    icon: PeopleAlt,
    menuIndex: 3,
    subMenus: [
      {    
        path: '/ujugyfel',
        menuIndex: 31,
        navbarName: 'ÚJ ÜGYFÉL',
        icon: PersonAdd,
        component: AddNewCustomerPage,
      },
      {    
        path: '/ugyfelkezelo',
        menuIndex: 32,
        navbarName: 'ÜGYFÉLKEZELŐ',
        icon: AccountTreeIcon,
        component: CustomerManagerPage,
      }
    ]
  },
  {//KEZELŐ
    path: '/',
    menuIndex: 4,
    navbarName: "ALKALMAZOTTKEZELÉS",
    icon: ContactMailIcon,
    component: EmployeeWorksheet,
    subMenus: [
      
      {    
        path: '/ujalkalmazott',
        menuIndex: 41,
        navbarName: 'ÚJ ALKALMAZOTT',
        icon: PersonAdd,
        component: AddNewEmployeePage,
      },
      {    
        path: '/alkalmazottkezelo',
        menuIndex: 42,
        navbarName: 'ALKAMAZOTTKEZELŐ',
        icon: AccountTreeIcon,
        component: EmployeeManagerPage,
      },
      {    
        path: '/munkanaplo',
        menuIndex: 44,
        navbarName: 'MUNKANAPLÓ',
        icon: TimerIcon,
        component: EmployeeWorksheet,
      }
    ]
  },
  {
    path: '/beallitasok',
    menuIndex: 5,
    navbarName: 'BEÁLLÍTÁSOK',
    icon: SettingsIcon,
    component: GlobalSettingsPage,
    subMenus: []
  },
  {
    path: '/admin',
    menuIndex: 6,
    navbarName: 'ADMIN SZERVÍZ',
    icon: PermDataSettingIcon,
    component: AdminService,
    subMenus: []
  }
  /*{
    path: '/naptar',
    menuIndex: 3,
    navbarName: 'NAPTÁR',
    icon: CalendarIcon,
    component: Dashboard,
    subMenus: []
  }*/
];

export default Routes;