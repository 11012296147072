import React from 'react'
import NewProductFormPage from '../../NewProductPage/NewProductFormPage'

import handleProductDetailsChangeSubmit from './handleProductDetailsChangeSubmit'

import {ProductFormValues} from '../../NewProductPage/NewProductForm'
import {DeleteProductDialog} from './DeleteProductDialog'

let ProductDetailsForm = (props : any) => {
  return (
    <div>
      <NewProductFormPage
        resetForm = {false}
        id = {props.productId} 
        initialName = {props.initialName} 
        initialType = {props.initialType} 
        initialNetUnitPrice = {props.initialNetUnitPrice}
        initialArticleNumber = {props.initialArticleNumber}
        initialCustomTariffNumber = {props.initialCustomTariffNumber}
        initialQuantity = {props.initialQuantity}
        initialUnit = {props.initialUnit}
        initialNotes= {props.initialNotes}
        handleSubmit = {(product: ProductFormValues) => {handleProductDetailsChangeSubmit(product);
        }}
        >
    </NewProductFormPage>
    <DeleteProductDialog 
      productId = {props.productId} 
      productName = {props.initialName}/>
    </div>
  )
}

export default ProductDetailsForm