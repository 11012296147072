export interface ITypeTranslation {
    translation: string;
 }

export class WorkStatusTranslator {
    static translations: { [id: string]: ITypeTranslation; } = {
        "SurveyAppointmentMaking": { translation: "Felméréshez időpont egyeztetés" },
        "QuotationMaking": { translation: "Árajánlat készítés" },
        "ImplementationAppointmentMaking": { translation: "Kivitelezéshez időpontegyeztetés" },
       // "s" : "Anyagbeszerzes alatt" //kivitelezes kezdese
        "BeingImplemented": { translation: "Kivitelezés alatt" },
        "UnderPayment": { translation: "Fizetés alatt" },
        "Closed": { translation: "Lezárva" }
     };


    translate(type: string) : string {
        try {
            return WorkStatusTranslator.translations[type].translation 
        } catch (error) {
            throw new TypeError(`The status ${type} can not be translated because there is not corresponding translation specified`)
        }
    }
}
