import Button from "@material-ui/core/Button";
import React from "react";
import TextField from "@material-ui/core/TextField";
import {Field, FormikProps } from 'formik';
import ProductTypes from '../ProductTypes'
import Units from '../Units'
import { Select } from "material-ui-formik-components";
import { ProductTypeTranslator} from '../Domain/Entities/ProductTypeTranslator'
import { UnitTranslator} from '../Domain/Entities/UnitTranslator'
import {CurrencyNumberFormat} from 'Core/Components/Form/CurrencyNumberFormat'
import {DeleteProductDialog} from '../ProductDetailsPage/ProductDetails/DeleteProductDialog'

//When add new feld: NewProductForm, \, ProductsSearchGrid, NewProductPageWrapper, ProductDetailsForm, NewProductPage
export interface ProductFormValues {
  id: string;
  name: string;
  type: string;
  articleNumber: string;
  customTariffNumber?: string;
  quantity: string;
  unit: string;
  netUnitPrice: string;
  notes?: string;
}

export interface OtherProps {
  title?: string;
}

export interface ProductFormProps {
  resetForm: Boolean;
  id?: string;
  initialName?: string;
  initialType?: string;
  initialArticleNumber?: string;
  initialCustomTariffNumber?: string;
  initialNetUnitPrice?: string;
  initialQuantity?: string;
  initialUnit?: string;
  initialNotes?: string;
  handleSubmit(productData : object) : void
}

const NewProductForm = (props: OtherProps & FormikProps<ProductFormValues>) => {
  const {
    values,
    handleSubmit,   
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched
  } = props;
 
  const change = (name: any, e: any) => {
    handleChange(e);
    setFieldTouched(name, true, false);
  };


  var productTypeTranslator = new ProductTypeTranslator();
  var unitTranslator = new UnitTranslator();


  return (
   <form      
      onSubmit={handleSubmit}>
        <TextField
          id="name"
          name="name"
          helperText={touched.name ? errors.name : ""}
          error={touched.name && Boolean(errors.name)}
          label="Termék név"
          value={values.name}
          onChange={change.bind(null, "name")}
          fullWidth
        />
        <Field
            required
            name="type"
            label="Tipus"
            options={ProductTypes.getAll().map(type => ({value: type, label: productTypeTranslator.translate(type)})).sort((a, b) => a.label.localeCompare(b.label))}
            component={Select}
          />
        <TextField
          id="articleNumber"
          name="articleNumber"
          helperText={touched.articleNumber ? errors.articleNumber : ""}
          error={touched.articleNumber && Boolean(errors.articleNumber)}
          label="Cikkszám"
          value={values.articleNumber}
          onChange={change.bind(null, "articleNumber")}
          fullWidth
        />
        <TextField 
          id="customTariffNumber"
          name="customTariffNumber"
          helperText={touched.customTariffNumber ? errors.customTariffNumber : ""}
          error={touched.customTariffNumber && Boolean(errors.customTariffNumber)}
          label="VTSZ szám"
          value={values.customTariffNumber}
          onChange={change.bind(null, "customTariffNumber")}
          fullWidth
        />
        <TextField
          id="netUnitPrice"
          name="netUnitPrice"
          label="Egységár (Áfa nélkül)" 
          value={values.netUnitPrice}
          onChange={change.bind(null, "netUnitPrice")}
          InputProps={{
            inputComponent: CurrencyNumberFormat,
          }}
          fullWidth
        />
        <TextField
          id="quantity"
          name="quantity"
          type="number"
          helperText={touched.quantity ? errors.quantity : ""}
          error={touched.quantity && Boolean(errors.quantity)}
          label="Mennyiség"
          value={values.quantity}
          onChange={change.bind(null, "quantity")}
          fullWidth
        />
        <Field
          required
          name="unit"
          label="Mértékegység"
          options={Units.getAll().map(unit => ({value: unit, label: unitTranslator.translate(unit)}))}
          component={Select}
        />
        <TextField
          id="notes"
          name="notes"
          multiline
          helperText={touched.notes ? errors.notes : ""}
          error={touched.notes && Boolean(errors.notes)}
          label="Megjegyzés"
          value={values.notes}
          onChange={change.bind(null, "notes")}
          fullWidth
      />
        <div className="emptyLine"> </div>
        <Button 
            type="submit" 
            variant="contained" 
            color="secondary" 
            disabled={!isValid || props.values.articleNumber === ""}>
                Mentés
        </Button>
          </form>
 );
};

export default NewProductForm