import axios, { AxiosResponse } from 'axios';
import userManager from 'Core/Services/Authentication/Logic/userManager';
import { REACT_APP_AUTH_URL } from 'Envs';

axios.interceptors.request.use(function (config) {
    var jwt = window.sessionStorage.getItem(`oidc.user:${REACT_APP_AUTH_URL()}:spa`)!;
    if (jwt == null) {
        //console.log("JWT can not be retrieved from sessions storage.")
        return config;
    }

    var jwtJson = JSON.parse(jwt)

    if (jwtJson.access_token != null) {
        //console.log("Access token is retrieved from session storage and added as authorization request header:")
        //console.log(jwtJson.access_token)
        config.headers.Authorization = `Bearer ${jwtJson.access_token}`;
    }

    return config;
}, function (err) {
    return Promise.reject(err);
});

export const get = async (endpointUrl: string): Promise<AxiosResponse<any>> => {
    return axios.get(endpointUrl);
}

export const getBlob = async (endpointUrl: string): Promise<AxiosResponse<any>> => {
    return axios.get(endpointUrl, { responseType: 'blob' });
}

export const deleteRequest = async (endpointUrl: string): Promise<AxiosResponse<any>> => {
    return axios.delete(endpointUrl);
}

export const post = async (endpointUrl: string, data?: any): Promise<AxiosResponse<any>> => {
    return axios.post(endpointUrl, data, { headers: { 'Content-Type': 'application/json' } });
}

export const put = async (endpointUrl: string, data: any): Promise<AxiosResponse<any>> => {
    return axios.put(endpointUrl, data, { headers: { 'Content-Type': 'application/json' } });
}

export const patch = async (endpointUrl: string, data: any): Promise<AxiosResponse<any>> => {
    return axios.patch(endpointUrl, data, { headers: { 'Content-Type': 'application/json' } });
}

//Not used because having some problem when the web app is iddle for a time, then the first retrieving returns an old (probably cached?) token
const setAccessToken = async (): Promise<void> => {
    console.log('Access token is retrieved from web storage')
    userManager.getUser()
        .then(usr => {
            if (usr) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + usr!.access_token;
            }
        })
        .catch(err => {
            console.log("An error has been happened while retrieving access token from web storage. Error: " + err)
            throw new Error("An error has been happened while retrieving access token from web storage. Error: " + err)
        });
} 