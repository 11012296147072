import React from "react";

import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from "@material-ui/pickers";

import moment from 'moment'

interface IWorkTime {
    value: Date,
    onChange: any
    validate: any
}

const WorkTimePicker = (props : IWorkTime) => {

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                value={props.value || ''}
                ampm={false}
                format = 'HH:mm'
                onChange={props.onChange}
                error = {!moment(props.value).isValid() || !props.validate()}
                helperText={
                    (moment(props.value).isValid() && props.validate())
                    ? null
                    : "Az idő rosszul lett megadva"
                }
                KeyboardButtonProps={{
                    "aria-label": "change time"
                }
                }
            />
        </MuiPickersUtilsProvider>
    )
}

export default WorkTimePicker