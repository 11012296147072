import React, { CSSProperties } from 'react';
import { useState, useEffect} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PaymentSummary from './PaymentSummary';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import urlJoin from 'url-join'
import {handleError} from 'Core/Utils/ApiErrorHandler'
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {get} from 'Core/Utils/api'
import {IWork} from '../CustomerManagement/types'
import {IEmployee} from './types'
import {Button} from '@material-ui/core';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';

import handleCreateWorkTable from './handleCreateWorkTable'
import handleDownloadWorksheet from './handleDownloadWorksheet'
import {ILeftDaysOffViewModel, IWorkSheetDetails} from './types'
import { CarTypeTranslator } from 'EmployeeManagement/CarTypeTranslator';
import {EditWorksheetDetailsDialog} from './EditWorksheetDetailsDialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    employeeDetails:  {
      marginLeft: 10,
      width : 800
    },
    employeeDetailsInfoField: {
      margin: 10,
      fontSize: 14,
      display: 'flex',
      flexDirection: 'row'
    }
  }),
);

function IsArrayContainsData(array: any) {
  return Boolean(array.length)
}

interface IVacationDaysDetailsProps {
    employee : IEmployee
    workDays: Array<IWork>
    year : number
    month : number
}

const VacationDaysDetails = (props: IVacationDaysDetailsProps) => {
  const classes = useStyles();

  const [leftDaysOff, setLeftDaysOff] = useState<ILeftDaysOffViewModel>();

  const calculateNumberOfDaysOffInMonth = () => {
    return props.workDays.filter(w => w.isDayOff).length
  }

  const calculateNumberOfSickPaysInMonth = () => {
    return props.workDays.filter(w => w.isSickPay).length
  }

  const calculateNumberOfNotPayedDaysOffInMonth = () => {
    return props.workDays.filter(w => w.isNotPayedDayOff).length
  }

const isAnnualDaysOffPresentForUser = async () => {
  if(props.employee.id && props.year) {
    const getAnnualDaysOff = urlJoin(REACT_APP_WAREHOUSING_URL(), 'employees', props.employee.id ,'annualdaysoff', props.year.toString());
    try {
      const res = await get(getAnnualDaysOff)
      if(res.data === '') {
        return false
      }
      return true;

      } catch (e) {
        toast.error(`Hiba az adatok töltése közben: ${e}`)
        return false;
    }
  }
}

  const refreshLeftOverDaysOff = async () => {
    var isAnnualDaysOffPresent = await isAnnualDaysOffPresentForUser();

    if(isAnnualDaysOffPresent && props.employee.id && props.year) {
      const getAnnualDaysOff = urlJoin(REACT_APP_WAREHOUSING_URL(), 'employees', props.employee.id ,'annualdaysoff', props.year.toString(), 'left');
      get(getAnnualDaysOff)
          .then(res => {
              setLeftDaysOff(res.data)
          })
          .catch(error => {
              toast.error(`Hiba az adatok töltése közben: ${error}`)
          }) 
    } else{
      setLeftDaysOff({})
    }
  }

  useEffect(() => {
    refreshLeftOverDaysOff();
  }, [props.workDays, props.employee, props.month, props.year]) 

  return (
      <div className = {classes.employeeDetails}>
          <div className="widgetTitle">Szabadnapok:</div>
          <div className= {classes.employeeDetailsInfoField}>
              <Typography color="textSecondary" gutterBottom>
              Hátralévő szabadnapok az évben:&nbsp;
              </Typography>
              <Typography variant="body1" gutterBottom>
                {props.employee.id && props.year 
                  ? leftDaysOff?.numberOfLeftDaysOff 
                  : '-'}
              </Typography>
          </div>
          <div className= {classes.employeeDetailsInfoField}>
              <Typography color="textSecondary" gutterBottom>
              Kivett szabadnapok ebben a hónapban:&nbsp;
              </Typography>
              <Typography variant="body1" gutterBottom>
                {IsArrayContainsData(props.workDays) 
                  ? calculateNumberOfDaysOffInMonth() 
                  : '-'}
              </Typography>
          </div>
          <div className= {classes.employeeDetailsInfoField}>
              <Typography color="textSecondary" gutterBottom>
              Táppénzes napok ebben a hónapban:&nbsp;
              </Typography>
              <Typography variant="body1" gutterBottom>
                {IsArrayContainsData(props.workDays) 
                  ? calculateNumberOfSickPaysInMonth() 
                  : '-'}
              </Typography>
          </div>
          <div className= {classes.employeeDetailsInfoField}>
              <Typography color="textSecondary" gutterBottom>
              Nem fizetett szabadság ebben a hónapban:&nbsp;
              </Typography>
              <Typography variant="body1" gutterBottom>
                {IsArrayContainsData(props.workDays) 
                  ? calculateNumberOfNotPayedDaysOffInMonth() 
                  : '-'}
              </Typography>
          </div>
      </div>
  );
}

export default VacationDaysDetails