import {patch} from 'Core/Utils/api'

import urlJoin from 'url-join'
import { toast } from 'react-toastify';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {handleError} from 'Core/Utils/ApiErrorHandler';
import { IWorkSheetDetails} from "./types";

const handleEditEmployeeSubmit = async (employeeId: String, year: number, month: number, worksheetDetails : IWorkSheetDetails) => {
  var warehousingUrl = REACT_APP_WAREHOUSING_URL();
  var customersEndpoint = urlJoin(warehousingUrl, 'employees', employeeId.toString(), 'workdays', year.toString(),month.toString(),'worksheetdetails')

  patch(customersEndpoint,worksheetDetails)
    .then((success) => {
      toast.success("Az munkanapló számitási adatok módosítása sikeresen megtörtént")
      console.log(success);
    }).catch((error) => {
      handleError(error, "Hiba a munkanapló számitási adatok módosítása közben");
    })

} 
export default handleEditEmployeeSubmit
