import { AxiosError } from 'axios'
import { formatErrors } from './ErrorsFormatter'
import { toast } from 'react-toastify';


export const handleError = (errorResponse: AxiosError, defaultToastErrorMessage: string) => {
  if (errorResponse.response) {
    switch (errorResponse.request.status) {
      case 400:
        handleResponseError(errorResponse, 'Kliens validációs hiba történt a művelet végrehajtása közben. Kérjük forduljon a rendszergazdához!');
        break;
      case 401:
        handleResponseError(errorResponse, "Nincs jogosultsága a művelet végrehajtásához. Kérjük jelentkezzen be újra vagy forduljon a rendszer gazdához");
        break;
      case 409:
        handleDomainErrors(errorResponse, defaultToastErrorMessage);
        break;
      case 500:
        handleResponseError(errorResponse, 'Service belső hiba történt a művelet végrehajtása közben. Kérjük forduljon a rendszergazdához!')
        break;
      default:
        handleResponseError(errorResponse, 'Nem ismert hiba történt művelet végrehajtása közben. Kérjük forduljon a rendszergazdához!')
    }
  } else {
    handleUnexpectedError(errorResponse, 'Nem várt felhasználói felület hiba történt. Kérjük forduljon a rendszergazdához!')
  }
}

const handleDomainErrors = (errorResponse: AxiosError, defaultToastErrorMessage: string) => {
  var errorMessage = `${defaultToastErrorMessage}:\r\n` + formatErrors(errorResponse.response!.data.errors);
  handleResponseError(errorResponse, errorMessage)
}

const handleResponseError = (errorResponse: AxiosError, errorMessage : string) => {
  toast.error(errorMessage);
  console.log(errorMessage);
  console.log(errorResponse.response) 
}

const handleUnexpectedError = (errorResponse: AxiosError, errorMessage: string) => {
  toast.error(errorMessage);
  console.log(errorMessage);
  console.log(errorResponse.message) 
}