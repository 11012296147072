import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import handleEditCustomer  from "./handleEditCustomer";
import CustomerForm  from "../../NewCustomer/CustomerForm";
import {ICustomer} from '../../types'

const useStyles = makeStyles(theme => ({
    form : {
      width: '400px'
    }
    ,
    button: {
      marginTop : theme.spacing(3)
    }
}));

interface EditCustomerDialogProps {
  editCustomerFormInitialValues : ICustomer
  setCustomerData : Function
}

export const EditCustomerDialog = (props : EditCustomerDialogProps) =>  {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button 
        type="submit"
        className={classes.button} 
        variant="contained"
        color="secondary"
        disabled = {props.editCustomerFormInitialValues.name === ""}
        onClick={handleClickOpen} >
           Szerkesztés
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Ügyfél szerkesztése"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CustomerForm 
              handleSubmit = {(c : ICustomer) => {
                handleEditCustomer(c); 
                setOpen(false);
                props.setCustomerData(c)
              }} 
              initialValues = {props.editCustomerFormInitialValues}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}