import React from 'react';
import  ProductQuantityIncrementerFormContainer from "./ProductQuantityIncrementerForm";
import  {QuantityIncrementerFormValues,  IncrementerInitialValues} from "./ProductQuantityIncrementerForm";
import * as Yup from "yup"
import { withFormik} from 'formik';
import classNames from 'classnames';

import handleProductQuantityChangeSubmit from './handleProductQuantityChangeSubmit'

const isRequiredText = (name: any) => name + " megadása kötelező"

const InputForm = withFormik<IncrementerInitialValues, QuantityIncrementerFormValues>({
    mapPropsToValues: props => ({
        productId: props.productId || "",
        quantity: props.initialQuantity || 0, //Default value is needed because overllapping issue with when initial value changes in parent
        incrementQuantity: 0
    }),
    enableReinitialize: true,

    handleSubmit(
        { productId, quantity }: QuantityIncrementerFormValues,
        { props, setSubmitting, setErrors }
    ) {
        handleProductQuantityChangeSubmit({productId, quantity});
    }
})(ProductQuantityIncrementerFormContainer);


export default InputForm

