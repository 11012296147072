export interface ITypeTranslation {
    translation: string;
 }

export class HistoryTypeTranslator {
    static translations: { [id: string]: ITypeTranslation; } = {
        "Add": { translation: "Raktárhoz adva" },
        "Pick": { translation: "Munkához kivéve" },
        "Unpick": { translation: "Visszatéve" },
        "ManualChange": { translation: "Manuálisan változtatva" },
        undefined: {translation: ''}
     };


    translate(type: string) : string {
        if(type == undefined) {
            console.log("HistoryType can not be translated because the product line has no type")
            return ''
        }
        return HistoryTypeTranslator.translations[type].translation
    }
}

