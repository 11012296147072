import React, { CSSProperties } from 'react';
import { useState, useEffect} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import deburr from 'lodash/deburr';
import urlJoin from 'url-join'
import {handleError} from 'Core/Utils/ApiErrorHandler' 

import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {get} from 'Core/Utils/api'

interface IPeopleListProps {
  setSelectedPerson : any
  listEndpointRoute: string
  height: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 300,
      backgroundColor: theme.palette.background.paper,
    },
    scrollWindow: {
      borderStyle: 'solid',
      borderColor: '#D0D0D0'
    }
  }),
);

const cssForSelectedPerson = {
  backgroundColor: '#0091ea',
  color: '#ffffff'
} as CSSProperties

const whiteBackground = {
  backgroundColor: '#ffffff',
} as CSSProperties


const Row = ({ data, index, style } : any) => 
{
  return (
    <ListItem button style={style} key={index} onClick={() => {
      data.setActiveIndex(index);
      data.setSelectedPerson(data.people[index])
    }}>
      <ListItemText style={data.activeIndex === index  ? cssForSelectedPerson : whiteBackground}  aria-selected primary={data.people[index].name} />
    </ListItem>
  );
}

const PeopleList= <TPerson extends any>(props: IPeopleListProps) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [people, setPeople] = useState<TPerson[]>();

  const fetchPeople = () => {
    get(urlJoin(REACT_APP_WAREHOUSING_URL(), props.listEndpointRoute))
      .then(response => {
        setPeople(response.data)
        //console.log(response.data)
      })
      .catch(function (error) {
        handleError(error, "Nem várt hiba történt az emberek betöltése közben")
      });
  }

  useEffect(() => {
    fetchPeople();
  }, []) 

  if (people === undefined) {
    return <>Töltése folyamatban...</>;
  }

  return (
    <div className={classes.root}>
      <FixedSizeList
        className={classes.scrollWindow}
        height={props.height}
        itemCount={people.length}
        itemSize={35}
        width={300}
        itemData={{
          setActiveIndex : setActiveIndex, 
          activeIndex: activeIndex, 
          people: people, 
          setSelectedPerson: props.setSelectedPerson }}
      >
        {Row}
      </FixedSizeList>
    </div>
  );
}

export default PeopleList