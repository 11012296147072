import React, { CSSProperties } from "react";
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import { get } from 'Core/Utils/api'
import {handleError} from 'Core/Utils/ApiErrorHandler' 

import urlJoin from 'url-join'
import { toast } from 'react-toastify';

function roundToOneDecimals(num: number | undefined) {    
  return Math.round(num! * 10) / 10;
}

function roundWithoutDecimals(num: number | undefined) {    
  return Math.round(num!);
}

const calculationColumnStyle = {
  textAlign: 'right',
  paddingLeft: 60,
} as CSSProperties

const priceColumnStyle = {
  textAlign: 'right',
  paddingLeft: 60,
  whiteSpace: 'nowrap'
} as CSSProperties

interface IWorkMonthSummary {
  hoursInMonthWithGeneralPayment: number
  generalHourlyWage : number
  generalPaymentSummary : number
  hoursInMonthWithBigWorkPayment : number
  bigWorkHourlyWage : number
  bigWorkPaymentSummary : number
  hoursInMonthForTravellingAsDriver : number,
  travellingAsDriverPaymentSummary : number,
  hoursInMonthForTravellingAsPassenger : number,
  travellingAsPassengerPaymentSummary : number,
  plusWages : Array<number>
  plusWagesSummary : number
  travelDistance: number
  travelCost: number
  finalTotalSummary : number

}

interface IPaymentSummaryProps {
  employeeId: string,
  year: number,
  month: number,
  workDays: Array<any>
}

const PaymentSummary = (props : IPaymentSummaryProps) => {
  const { useState, useEffect} = React;

  const [workMonthSummary, setWorkMonthSummary] = useState<IWorkMonthSummary>();

  const refreshPaymentSummary = () => {
    if(props.employeeId && props.year && props.month) {
      const employeeWorkDaysForDateUrl = urlJoin(
        REACT_APP_WAREHOUSING_URL(), 'employees', props.employeeId ,'workmonthsummary', props.year.toString(), props.month.toString());
        get(employeeWorkDaysForDateUrl)
            .then(res => {
              setWorkMonthSummary(res.data)
            })
            .catch(error => {
              handleError(error, "Hiba az összegzés töltése közben");
            })        
        }
}

    useEffect(() => {
      refreshPaymentSummary();
    }, [props.employeeId, props.year, props.month, props.workDays]) 

    return(
      <div style = {{padding: 15, width: 600}}>
        <div className="widgetTitle">Összegzés</div>
        <table style = {{width: '100%'}}>
              <tr>
                  <td><strong>Általános munka:</strong></td>
                  <td style = {calculationColumnStyle}>{roundToOneDecimals(workMonthSummary?.hoursInMonthWithGeneralPayment)} óra * {workMonthSummary?.generalHourlyWage} Ft/óra</td>
                  <td style = {priceColumnStyle}>{roundWithoutDecimals(workMonthSummary?.generalPaymentSummary)} Ft</td>
              </tr>
              <tr>
                  <td><strong>Nagymunka:</strong></td>
                  <td style = {calculationColumnStyle}>{roundToOneDecimals(workMonthSummary?.hoursInMonthWithBigWorkPayment)} óra * {workMonthSummary?.bigWorkHourlyWage} Ft/óra</td>
                  <td style = {priceColumnStyle}>{roundWithoutDecimals(workMonthSummary?.bigWorkPaymentSummary)} Ft</td>
              </tr>
          </table>
          <hr />
          <table style = {{width: '100%'}}>
              <tr>
                  <td><strong>Általános + nagymunka:</strong></td>
                  <td style = {calculationColumnStyle}></td>
                  <td style = {priceColumnStyle}><strong>{roundWithoutDecimals(workMonthSummary?.generalPaymentSummary) + roundWithoutDecimals(workMonthSummary?.bigWorkPaymentSummary)} Ft</strong></td>
              </tr>
        </table>
        <br></br>
        <br></br>
          <table style = {{width: '100%'}}>
              <tr>
                  <td><strong>Útazás sofőrként:</strong></td>
                  <td style = {calculationColumnStyle}>{roundToOneDecimals(workMonthSummary?.hoursInMonthForTravellingAsDriver)} óra * {workMonthSummary?.bigWorkHourlyWage} Ft/óra</td>
                  <td style = {priceColumnStyle}>{roundWithoutDecimals(workMonthSummary?.travellingAsDriverPaymentSummary)} Ft</td>
              </tr>
              <tr>
                  <td><strong>Útazás utasként:</strong></td>
                  <td style = {calculationColumnStyle}>{roundToOneDecimals(workMonthSummary?.hoursInMonthForTravellingAsPassenger)} óra * {workMonthSummary?.bigWorkHourlyWage!/2} Ft/óra</td>
                  <td style = {priceColumnStyle}>{roundWithoutDecimals(workMonthSummary?.travellingAsPassengerPaymentSummary)} Ft</td>
              </tr>
              <tr>
                  <td><strong>Út bér:</strong></td>
                  <td style = {calculationColumnStyle}>{workMonthSummary?.travelDistance} km</td>
                  <td style = {priceColumnStyle}>{roundWithoutDecimals(workMonthSummary?.travelCost)} Ft</td>
              </tr>
              <tr>
                  <td><strong>Plus bér:</strong></td>
                  <td style = {calculationColumnStyle}>{workMonthSummary?.plusWages.join(", ")}</td>
                  <td style = {priceColumnStyle}>{roundWithoutDecimals(workMonthSummary?.plusWagesSummary)} Ft</td>
              </tr>
        </table>
        <hr />
        <table style = {{width: '100%'}}>
              <tr>
                  <td><strong>Összesen:</strong></td>
                  <td style = {calculationColumnStyle}></td>
                  <td style = {priceColumnStyle}><strong>{roundWithoutDecimals(workMonthSummary?.finalTotalSummary)} Ft</strong></td>
              </tr>
        </table>
      </div> 

    );
}

export default PaymentSummary