import {post} from 'Core/Utils/api'

import urlJoin from 'url-join'
import { toast } from 'react-toastify';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {handleError} from 'Core/Utils/ApiErrorHandler';

//TODO: the state is probably not changed if we  do handleNewProductSubmit, therefore the page is not rerendered

const handleNewProductSubmit = (newProduct: any) => {
  console.log(newProduct);
  var warehousingUrl = REACT_APP_WAREHOUSING_URL();
  post(urlJoin(warehousingUrl, 'products'), newProduct)
    .then(response => {
      toast.success(`Az új cikk ${newProduct.name} sikeresen hozzá lett adva a raktárhoz`)
      console.log(response);
    })
    .catch(error => {
      handleError(error, 'Hiba történt az új cikk hozzáadása közben')
    });
}

export default handleNewProductSubmit
