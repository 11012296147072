import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { Formik, Form } from "formik";
import * as yup from "yup";

import {ICustomer} from '../types'

const isRequiredText = (name: any) => name + " megadása kötelező"

let SignupSchema = yup.object().shape({
  name: yup
    .string()
    .required(isRequiredText("Név"))
    .max(255, "A Név nem lehet nagyobb 255 karakternél"),
  address: yup
    .string()
    .max(1000, "A cím nem lehet nagyobb 1000 karakternél")
    .required(isRequiredText("Cím")),
  phone : yup
    .string()
    .max(20, "A telefonszám nem lehet nagyobb 20 karakternél"),
  email: yup
    .string()
    .email("Az e-mail cím nem megfelelő")
    .max(100, "Az e-mail nem lehet nagyobb 100 karakternél"),
  notes: yup
    .string()
    .max(1000, "A megjegyzés nem lehet nagyobb 1000 karakternél")
});

interface CustomerFormProps {
    handleSubmit : Function
    initialValues? : ICustomer
}

const CustomerForm = (props : CustomerFormProps) => {
  var customerWithInitValues : ICustomer = {
    name: props.initialValues ? props.initialValues.name : "",
    address: props.initialValues ? props.initialValues.address : "",
    phone: props.initialValues ? props.initialValues.phone : "",
    email: props.initialValues ? props.initialValues.email : "",
    notes: props.initialValues ? props.initialValues.notes : ""
  }
  return (
    <div style={{width: 300}}>
    <Formik
        initialValues={customerWithInitValues}
        validationSchema={SignupSchema}
        onSubmit={(values, {resetForm}) => {
          let customer = JSON.parse(JSON.stringify(values)) as ICustomer
          customer.id = props.initialValues ? props.initialValues.id : "";
          
          props.handleSubmit(customer)
          resetForm();
        }}
      >
        {({ errors, handleChange, touched,values }) => (
          <Form>
            <TextField
              error={Boolean(touched.name) && Boolean(errors.name)}
              autoComplete="fname"
              name="name"
              value={values.name}
              fullWidth
              onChange={handleChange}
              id="name"
              label="Név"
              autoFocus
              helperText={
                errors.name && touched.name
                  ? errors.name
                  : null
              }
            />
            <TextField
              error={Boolean(touched.address) && Boolean(errors.address)}
              autoComplete="fname"
              name="address"
              value={values.address}
              fullWidth
              onChange={handleChange}
              id="address"
              label="Cím"
              helperText={
                errors.address && touched.address
                  ? errors.address
                  : null
              }
            />
            <TextField
              error={Boolean(touched.phone) && Boolean(errors.phone)}
              autoComplete="fname"
              name="phone"
              value={values.phone}
              fullWidth
              onChange={handleChange}
              id="phone"
              label="Telefonszám"
              helperText={
                errors.phone && touched.phone
                  ? errors.phone
                  : null
              }
            />
            <TextField
              error={Boolean(errors.email) && Boolean(touched.email)}
              fullWidth
              onChange={handleChange}
              value={values.email }
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              helperText={
                errors.email && touched.email ? errors.email : null
              }
            />
            <TextField
              error={Boolean(errors.notes) && Boolean(touched.notes)}
              fullWidth
              multiline
              onChange={handleChange}
              value={values.notes }
              name="notes"
              label="Megjegyzés"
              type="notes"
              id="notes"
              helperText={
                errors.notes && touched.notes
                  ? errors.notes
                  : null
              }
            />
            <div className="emptyLine"> </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Mentés
            </Button>
          </Form>
        )}
      </Formik>
      </div>
      )
  }

export default CustomerForm