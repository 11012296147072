import {put} from 'Core/Utils/api'

import urlJoin from 'url-join'
import { toast } from 'react-toastify';
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {handleError} from 'Core/Utils/ApiErrorHandler';
import {IWork} from "../../types";

const handleEditCustomerSubmit = async (customerId : string, work : IWork, refreshWorksData:Function) => {
  var warehousingUrl = REACT_APP_WAREHOUSING_URL();
  var worksEndpoint = urlJoin(warehousingUrl, 'customers', customerId.toString(), "works", work.id.toString())

  put(worksEndpoint,work)
    .then((success) => {
      toast.success("Az munka módosítása sikeresen megtörtént")
      refreshWorksData(work);
      console.log(success);
    }).catch((error) => {
      handleError(error, "Hiba a munka módosítása közben");
    })

} 
export default handleEditCustomerSubmit
