import * as React from 'react';

import ProductsSearchGrid from './ProductsSearchGrid';

import './ProductsSearcher.css';
import '../Core/Components/Shared.css'


class ProductsSearcher extends React.Component {
  render() {
    return (
      <div className="widget">
        <ProductsSearchGrid> </ProductsSearchGrid>
      </div>
    );
  }
}
export default (ProductsSearcher);
