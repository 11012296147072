import urlJoin from 'url-join'
import { toast } from 'react-toastify';
import {ProductFormValues} from '../../NewProductPage/NewProductForm'
import {REACT_APP_WAREHOUSING_URL} from 'Envs'
import {put} from 'Core/Utils/api'
import {handleError} from 'Core/Utils/ApiErrorHandler'

const handleProductDetailsChangeSubmit = (product : ProductFormValues) => {
  console.log(product);
  let node = {
    "name": product.name,
    "type" : product.type,
    "articleNumber": product.articleNumber,
    "customTariffNumber": product.customTariffNumber,
    "netUnitPrice": product.netUnitPrice,
    "quantity" : product.quantity,
    "unit" : product.unit,
    "notes": product.notes
  }
  
  var productUrl = urlJoin(REACT_APP_WAREHOUSING_URL(),'products', product.id.toString());
  
  put(productUrl,node)
    .then((success) => {
      toast.success("Az termék módosítása sikeresen megtörtént")
      console.log(success);
    }).catch((error) => {
      handleError(error, "Hiba a termék módosítása közben");
    })
}

export default handleProductDetailsChangeSubmit
